.reporting-container {
    display: flex;
    height: 100vh;
    background: black;
    .sidebar {
        width: 250px;
        background: #3a1c71;
        padding: 20px;
        display: flex;
        flex-direction: column;
        color: white;
        box-shadow: 4px 0 10px rgba(0, 0, 0, 0.3);
    }

    .sidebar-title {
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 20px;
    }

    .home-icon {
        color: #e0b0ff;
    }

    .sidebar-button {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
        padding: 12px;
        border: none;
        border-radius: 5px;
        font-size: 1rem;
        cursor: pointer;
        transition: 0.3s;
        background: #5e239d;
        color: white;
    }

    .sidebar-button:hover {
        background: #7828c8;
    }

    .active {
        background: linear-gradient(135deg, #c471ed, #f64f59);
        font-weight: bold;
    }

    .button-label {
        flex-grow: 1;
    }

    .main-content {
        flex: 1;
        padding: 20px;
        background: white;
        box-shadow: -4px 0 10px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
    }

    .report-title {
        font-size: 2rem;
        color: #333;
    }
}


