.video-controls--time-display {
    height: calc(36rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(calc(-100rem/16));
    transition: transform .25s $sexyEase;
    &.volume-is-hovered {
        transform: translateX(0);
    }

    &.is-mobile {
        margin: 0 0 0 calc(7rem/16);
    }

    p {
        margin: 0 0 0 calc(4rem/16);
        font-size: calc(12rem/16);
    }

    @media screen and (min-width: $breakpointSmallTablet) {
        display: inherit;
        p {
            margin: 0 0 0 calc(4rem/16);
            font-size: calc(14rem/16);
        }
    }
}
