.video-player__loading-overlay {
    overflow: hidden;
    z-index: 11;
    &.is-hidden {
        opacity: 0;
        transform: scale(1.1);
        top: -200%;
        transition: opacity .25s $sexyEase, transform .25s $sexyEase, top 0s linear .25s;
    }
}
