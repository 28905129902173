.page--sign-up-success {
    min-height: 100vh;
    background: $white;
    .sign-up-success__loader {
        display: inline-block;
        width: 100%;
        transform: translateY(calc(5rem/16));
    }

    .page__header {
        box-shadow: 0 0 calc(10rem/16) 0 rgba(200,200,200,0.7);
        position: relative;
        z-index: 1;
    }

    .page__heading {
        padding: 2rem 1rem;

        background: lighten($lightestGrey, 3%);
        &:after {
            content: '';
            position: absolute;
            height: calc(5rem/16);
            width: 100%;
            bottom: 0;
            left: 0;
            background: linear-gradient(45deg, rgba(229,174,44,1) 0%, rgba(187,54,184,1) 100%);
        }

        svg {
            position: absolute;
            width: 200%;
            height: auto;
            top: -50vw;
            left: -50%;
            path {
                stroke: rgba(100,100,100,0.05);
            }
        }

        h1 {
            font-weight: bold;
            max-width: calc(640rem/16);
            margin: 0 auto;
            font-family: $tertiaryFont;
            color: $black;

            // Loader
            .u-relative_hidden {
                max-width: calc(90rem/16);
                height: calc(38rem/16);
            }
        }
    }

    .page__inner {
        padding: 1rem;
        margin: 0 auto;
        max-width: calc(700rem/16);
        & > p {
            font-family: $baseFont;
            & > span {
                margin: 0 0 0 calc(7rem/16);
            }
        }

        p {
            color: $darkGrey;
            font-size: calc(16rem/16);

            // Loader
            .u-relative_hidden {
                height: calc(25rem/16);
                max-width: calc(200rem/16);
            }

            .page__text-highlight {
                padding: calc(2rem/16) calc(8rem/16) calc(5rem/16) calc(8rem/16);
                background: $darkModeDark;
                box-shadow: inset 0 0 0 calc(2rem/16) rgba(183,96,180,0.5);
                border-radius: calc(6rem/16);
            }
        }

        > h1, > h2, > p {
            margin: 0 0 2rem 0;
        }

        .page__help-text {
            display: flex;
            align-items: center;
            justify-content: space-between;
            svg {
                height: calc(30rem/16);
                width: calc(30rem/16);
                * {
                    fill: lighten($darkModeLighter, 20%);
                }
            }

            span {
                margin: 0;
                width: calc(100% - (46rem/16));
                a {
                    color: $black;
                    text-decoration: underline;
                    &:hover,
                    &:focus,
                    &:active {
                        color: $sitePrimaryLightest;
                    }
                }
            }
        }

        .page__button-wrapper {
            position: relative;
        }

        .ickonic-button {
            text-transform: uppercase;
            text-shadow: calc(2rem/16) calc(2rem/16) calc(2rem/16) rgba(0, 0, 0, 0.3);
            color: $white;
            margin: 0;
            width: 100%;
            padding: calc(5rem/16) calc(25rem/16);
            &:first-of-type {
                animation: boxShadowGlowPulsePink 2s $sexyEase infinite;
                background: linear-gradient(90deg, #b760b4 0%, rgb(232,187,39) 100%);
                &:hover,
                &:focus,
                &:active {
                    background: linear-gradient(90deg, #b760b4 0%, rgb(232,187,39) 100%);
                }
            }

            &:last-of-type {
                position: absolute;
                background: $sitePrimaryLightest;
                opacity: 0;
                transition: opacity .25s $sexyEase;
                &:hover,
                &:focus,
                &:active {
                    background: $sitePrimaryLightest;
                }
            }

            &:hover,
            &:focus,
            &:active {
                &:last-of-type {
                    opacity: 1;
                }
            }
        }
    }

    @media screen and (min-width: $breakpointSmallTablet) {
        .page__heading {
            padding: 4rem 2rem 3rem 2rem;
        }

        .page__inner {
            padding: 2rem;

        }
    }

    &.is-dark-mode {
        .page__header {
            box-shadow: 0 0 calc(10rem/16) 0 rgba(0,0,0,0.7);
        }


        .page__heading {
            background: $darkModeDark;
            h1 {
                text-shadow: calc(1rem/16) calc(1rem/16) calc(3rem/16) #000;
                color: $white;
            }

            svg {
                path {
                    stroke: rgba(120,120,120,0.05);
                }
            }
        }

        .page__inner {
            p {
                color: $lighterGrey;
            }

            .page__help-text {
                span {
                    a {
                        color: darken($white, 20%);
                        text-decoration: underline;
                        &:hover,
                        &:focus,
                        &:active {
                            color: $sitePrimaryLightest;
                        }
                    }
                }
            }
        }
    }
}
