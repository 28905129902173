.page--browse {
    .page-browse__carousel-container {
        background: #151515;
        width: 100%;
        padding: 0 0 1rem 0;
    }

    @media screen and (min-width: $breakpointSmallTablet) {
        .page-browse__carousel-container {
            padding: 0 0 2.5rem 0;
        }
    }
}
