.content-share-buttons {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    border-radius: calc(20rem/16);
    .ickonic-button {
        background: #2e2e2e;
        font-weight: normal;
        height: calc(40rem/16);
        &:hover,
        &:focus,
        &:active {
            background: #404040;
        }

        svg {
            padding: calc(4rem/16);
            * {

            }
        }
    }

    .share-buttons__popup {
        position: fixed;
        top: -200%;
        left: 0;
        width: 100%;
        height: 100%;
        transform: scale(.9);
        opacity: 0;
        background: rgba(40,40,40,0.5);
        backdrop-filter: blur(calc(10rem/16));
        -webkit-backdrop-filter: blur(calc(10rem/16));
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity .45s $sexyEase, transform .45s $sexyEase, top 0s linear .45s;
        &.is-active {
            opacity: 1;
            transform: scale(1);
            top: 0;
            transition: opacity .45s $sexyEase, transform .45s $sexyEase, top 0s linear;
        }

        .share-buttons__popup-close {
            position: absolute;
            width: calc(30rem/16);
            height: calc(30rem/16);
            right: 1rem;
            top: 1rem;
            cursor: pointer;
            background: rgba(0,0,0,0.5);
            border-radius: 50%;
            svg {
                padding: calc(5rem/16);
                * {
                    fill: $white;
                }
            }
        }

        .share-buttons__popup-inner {
            border-radius: calc(10rem/16);
            box-shadow: 0 0 calc(5rem/16) 0 rgba(0,0,0,0.7);
            margin: 2rem;
            max-width: calc(500rem/16);
            width: calc(100% - 4rem);
            height: auto;
            background: darken($darkerGrey, 3%);
            padding: 2rem;
        }

        .share-buttons__share-options {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }

        .share-buttons__button {
            padding: calc(10rem/16);
            width: calc(60rem/16);
            height: calc(60rem/16);
            background: $black;
            border-radius: 50%;
            position: relative;
            margin: 0 calc(10rem/16) calc(10rem/16) 0;
            &#fb-share {
                background: #1877F2;
            }

            &#in-share {
                background: #0077B5;
                svg {
                    width: calc(25rem/16);
                    height: calc(25rem/16);
                }
            }

            &#x-share {
                svg {
                    width: calc(28rem/16);
                    height: calc(28rem/16);
                }
            }

            &#reddit-share {
                background: #FF4500;
            }

            &#minds-share {
                background: #ffd048;
                svg {
                    width: calc(35rem/16);
                    height: calc(35rem/16);
                    * {
                        fill: $black;
                    }
                }
            }

            &#email-share {
                background: darken($midGrey, 10%);
            }

            & > a {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &:last-child {
                margin: 0 0 calc(10rem/16) 0;
            }

            svg {
                width: calc(30rem/16);
                height: calc(30rem/16);
                * {
                    fill: $white;
                }
            }
        }

        .share-buttons__copy-link {
            padding: calc(10rem/16);
            background: $darkerGrey;
            border: calc(1rem/16) solid rgba(255,255,255,0.3);
            border-radius: calc(8rem/16);
            width: 100%;
            margin: 1rem 0 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            p {
                margin: 0;
                font-size: calc(14rem/16);
                max-width: calc(337rem/16);
                width: calc(100% - (46rem/16));
                white-space: nowrap;
                &:after {
                    content: '';
                    top: 0;
                    right: 0;
                    width: calc(20rem/16);
                    height: 100%;
                    background: linear-gradient(270deg, $darkerGrey 0%, rgba(32,32,32,0) 100%);
                    position: absolute;
                }
            }

            .ickonic-button {
                height: calc(30rem/16);
                margin: 0 0 0 calc(10rem/16);
                padding: calc(2rem/16) calc(10rem/16);
                background: $sitePrimary;
                &:hover,
                &:focus,
                &:active {
                    background: $sitePrimaryLightest;
                }
            }
        }
    }

    //> a,
    //> button {
    //    width: 37px;
    //    height: 37px;
    //    cursor: pointer;
    //    margin: 0;
    //    padding: 7px;
    //    border-radius: 50%;
    //    transition: background .15s $sexyEase;
    //    @media screen and (min-width: $breakpointLargeMobile) {
    //        margin: 0 5px 0 0;
    //    }
    //
    //    &:hover,
    //    &:focus {
    //        background: rgba(45,45,45);
    //        svg {
    //            * {
    //                fill: white;
    //            }
    //        }
    //    }
    //}
}
