.layout--triple-column-icons {
    padding: 1rem;
    .triple-column-icons__item {
        svg {
            display: block;
            margin: 0 auto 1rem auto;
            padding: calc(10rem/16);
            background: rgba(0,0,0,0.25);
            width: calc(50rem/16);
            height: calc(50rem/16);
            border-radius: 50%;
        }

        h2,
        p {
            text-align: center;
            max-width: calc(350rem/16);
            margin: 0 auto 1rem auto;
        }

        h2 {
            text-transform: uppercase;
        }

        p {
            font-size: calc(14rem/16);
            color: $lightGrey;
        }
    }

    @media screen and (min-width: $breakpointTablet) {
        .triple-column-icons__inner {
            display: flex;
            max-width: calc(1300rem/16);
            margin: 0 auto;
        }

        .triple-column-icons__item {
            width: 33.333333%;
            padding: 1rem;
        }
    }
}
