.cancel-or-pause__step-upcoming-content {
    .cancel-or-pause__content-wrapper {
        h3 {
            text-align: center;
            margin: 0 0 2rem 0;
        }

        @media screen and (min-width: $breakpointSmallTablet) and (min-height: $breakpointSmallMobile) {
            padding: 2rem 2rem 1rem 2rem;
        }
    }

    .step-upcoming-content__upcoming-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        & > div {
            width: calc(50% - (8rem/16));
            margin: 0 0 1rem 0;
            @media screen and (min-width: $breakpointSmallTablet) {
                width: calc(33.333333% - (10.666rem/16));
            }
        }
    }

    .step-upcoming-content__next-step {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 1rem 0 0 0;
        font-size: calc(14rem/16);
        .ickonic-button {
            margin: 0 0 0 calc(5rem/16);
            font-size: calc(12rem/16);
            padding: calc(2rem/16) calc(15rem/16);
        }

        @media screen and (min-width: $breakpointSmallTablet) {
            margin: 1rem 0;
        }
    }
}
