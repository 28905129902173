.layout--two-column-with-latest-articles {
    padding: 2rem 2rem 1rem 2rem;
    background: rgb(16,52,88);
    background: radial-gradient(circle, rgba(16,52,88,1) 0%, rgba(2,2,21,1) 100%);
    .two-column-with-latest-articles__content {
        width: auto;
        display: table;
        max-width: calc(500rem/16);
        padding: 0 0 2rem 0;
        position: relative;
        z-index: 3;
        h1 {
            font-family: $tertiaryFont;
            text-transform: uppercase;
            font-weight: bold;
            margin: 0;
            span {
                display: block;
                background: linear-gradient(180deg, #4676E6 0%, #b760b4 100%);
                background-clip: text;
                -webkit-background-clip: text;
                color: transparent;
            }

            @media screen and (min-width: $breakpointLargeMobile) {
                font-size: calc(35rem/16);
            }

            @media screen and (min-width: $breakpointDesktop) {
                font-size: calc(40rem/16);
            }

            @media screen and (min-width: $breakpointWide) {
                font-size: calc(45rem/16);
            }
        }

        .ickonic-button {
            font-weight: normal;
            color: $white;
            margin: 1.5rem 0 0 0;
            padding: calc(16rem/16) calc(35rem/16);
            border-radius: calc(30rem/16);
            &.has-dark-theme {
                color: $black;
            }

            svg {
                margin: 0 0 0 calc(10rem/16);
                width: calc(12rem/16);
                height: calc(12rem/16);
            }
        }
    }

    .category-featured-items__gradient-overlay-top,
    .category-featured-items__gradient-overlay-bottom {
        z-index: 0;
        height: calc(400rem/16);
    }

    .category-featured-items__gradient-overlay-bottom {
        top: unset;
        bottom: 0;
    }

    & > img {
        display: none;
    }

    .two-column-with-latest-articles__inner {
        max-width: calc(1300rem/16);
        margin: 0 auto;
    }

    .two-column-with-latest-articles__article {
        position: relative; // Ensure label positioning works
        border-radius: calc(20rem/16);
        margin: 0 0 1rem 0;
        z-index: 2;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 80%;
            background: rgba(255,255,255,0.05);
            border-bottom-right-radius: calc(20rem/16);
            border-bottom-left-radius: calc(20rem/16);
            backdrop-filter: blur(10px); /* Apply blur to the background behind this box */
            -webkit-backdrop-filter: blur(10px); /* Safari support */
        }

        .two-column-with-latest-articles__article-image {
            border-top-left-radius: calc(15rem/16);
            border-top-right-radius: calc(15rem/16);
        }

        .two-column-with-latest-articles__article-free-view {
            top: calc(6rem/16);
            z-index: 2;
        }

        .two-column-with-latest-articles__article-content {
            padding: 1rem 3rem 1rem 1rem;
            position: relative;
            height: calc(50% - 1rem);
            z-index: 1;
            h3 {
                margin: 0 0 2rem 0;
                font-family: $tertiaryFont;
                a {
                    color: $white;
                }

                @media screen and (min-width: $breakpointLargeMobile) {
                    font-size: calc(20rem/16);
                }
            }

            svg {
                position: absolute;
                top: calc(20rem/16);
                right: calc(16rem/16);
                width: calc(16rem/16);
                height: calc(16rem/16);
            }
        }

        p {
            margin: 0;
            position: absolute;
            z-index: 1;
            line-height: 1.1;
            bottom: calc(10rem/16);
            left: 1rem;
            width: calc(100% - 2rem);
            a {
                color: $midGrey;
                font-size: calc(14rem/16);
            }
        }
    }

    @media screen and (min-width: $breakpointLargeMobile) {
        padding: 2rem 2rem 1rem 2rem;
        .two-column-with-latest-articles__inner {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .two-column-with-latest-articles__article {
            width: calc(50% - (8rem/16));
            .two-column-with-latest-articles__article-content {
                h3 {
                    font-size: calc(14rem/16);
                }
            }

            p {
                a {
                    font-size: calc(12rem/16);
                }
            }
        }
    }

    @media screen and (min-width: calc(850em/16)) {
        padding: 10rem 1rem 3rem 1rem;
        .two-column-with-latest-articles__content {
            padding: 0 0 5rem 0;
            width: calc(500rem/16);
            margin: 0 0 0 50%;
        }

        & > img {
            display: inherit;
            position: absolute;
            bottom: 17rem;
            left: 0;
            width: calc(417rem/16);
            height: auto;
            z-index: 1;
        }

        .two-column-with-latest-articles__article {
            width: calc(25% - (8rem/16));

            .two-column-with-latest-articles__article-content {
                h3 {
                    font-size: calc(16rem/16);
                }
            }

            p {
                a {
                    font-size: calc(14rem/16);
                }
            }
        }
    }

    @media screen and (min-width: $breakpointDesktop) {
        padding: 15rem 1rem 5rem 1rem;
        .two-column-with-latest-articles__article {
            width: calc(25% - (16rem/16));
        }

        & > img {
            bottom: 17rem;
            width: calc(600rem/16);
        }
    }

    @media screen and (min-width: $breakpointWide) {
        padding: 22rem 1rem 5rem 1rem;
        & > img {
            left: calc(50% - (700rem/16));
            width: calc(700rem/16);
        }

        .two-column-with-latest-articles__article {
            .two-column-with-latest-articles__article-content {
                h3 {
                    font-size: calc(18rem/16);
                }
            }
        }
    }
}
