.ickonic--user-onboard {
    position: fixed;
    width: 100%;
    height: 100%;
    top: -200%;
    left: 0;
    z-index: 1000;
    background: rgba(0,0,0,0.75);
    opacity: 0;
    transform: scale(1.05);
    min-height: 100vh;
    overflow-y: auto;
    transition: opacity .45s $sexyEase, transform .45s $sexyEase, top 0s linear .45s;
    &.is-active {
        opacity: 1;
        top: 0;
        transform: scale(1.01);
        transition: opacity .45s $sexyEase, transform .45s $sexyEase, top 0s linear;
    }

    .popup__content {
        width: calc(100% - 2rem);
        max-width: calc(600rem/16);
    }

    .user-onboard__wrapper {
        padding: 1rem;
        background: $white;
        border-radius: calc(10rem/16);
        width: calc(100% - 2rem);
        position: absolute;
        top: 1rem;
        left: 1rem;

        transition: height .45s $sexyEase;
        .user-onboard__wrapper-window {

        }

        .user-onboard__steps {
            display: flex;
            align-items: flex-start;
            transition: transform .45s $sexyEase;
        }

        .user-onboard__step {
            width: 100%;
            opacity: 0;
            transition: opacity .45s $sexyEase;
            position: relative;
            &.is-active {
                opacity: 1;
            }

            & > .ickonic-button {
                width: 100%;
            }
        }

        h1 {
            margin: 0 0 .5rem 0;
            font-family: $tertiaryFont;
            font-size: calc(25rem/16);
            font-weight: bold;
            @media screen and (min-width: $breakpointTablet) {
                font-size: calc(30rem/16);
            }
        }

        h2 {
            margin: 0;
            font-weight: bold;
            font-family: $tertiaryFont;
        }

        h3 {
            font-family: $baseFont;
            margin: 0 0 1.5rem 0;
            font-size: calc(12rem/16);
            line-height: 1.4;
        }

        .user-onboard__heading-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            h2 {
                width: calc(100% - (90rem/16));
            }

            span {
                cursor: pointer;
                transform: translateY(calc(3rem/16));
                &:hover,
                &:focus,
                &:active {
                    color: $sitePrimaryLightest;
                }
            }
        }

        .user-onboard__close {
            font-size: calc(12rem/16);
            color: $midGrey;
        }

        p {
            margin: 1.5rem 0;
            font-family: $secondaryFont;
            font-size: calc(16rem/16);
            //font-size: calc(14rem/16);
            //padding: calc(8rem/16) calc(12rem/16);
            //background: darken($darkModeDark, 2%);
            //border-radius: calc(10rem/16);
        }

        .user-onboard__divider {
            background: linear-gradient(45deg, rgba(229,174,44,1) 0%, rgba(187,54,184,1) 100%);
            height: calc(2rem/16);
            width: 100%;
            margin: 1.5rem 0;
        }

        .multi-select-buttons {
            .ickonic-button {
                font-size: calc(10rem/16);
                border-radius: calc(7rem/16);
                margin: 0 calc(8rem/16) calc(8rem/16) 0;
                display: inline-block;
                padding: calc(7rem/16) calc(10rem/16);
                box-shadow: none;
                transition: none;
                font-family: $baseFont;
                background: none;
                letter-spacing: calc(1rem/16);
                &:hover {
                    &.is-not-active.is-not-dimmed {
                        background: $white;
                        color: $black;
                    }
                }

                &.dimmed {
                    opacity: 0.5;
                    cursor: inherit;
                }
            }
        }

        .user-onboard__button-wrapper {
            display: flex;
            justify-content: space-between;
            .ickonic-button {
                width: calc(80rem/16);
            }
        }

        .user-onboard__step-one {
            .ickonic-button__next-step {
                text-transform: uppercase;
                &.is-active {
                    box-shadow: inset 0 0 0 calc(2rem/16) $sitePrimary;
                    color: $white;
                    background: $darkModeDark;
                    &:hover,
                    &:focus,
                    &:active {
                        color: $white;
                        background: $darkModeDark;
                        box-shadow: inset 0 0 0 calc(4rem/16) $sitePrimary;
                    }
                }
            }
        }

        .user-onboard__step-four {
            &.is-active {
                .user-onboard__gradient-circle {
                    transform: scale(1);
                }
            }

            h1 {
                text-align: center;
                width: 100%;
            }

            p {
                text-align: center;
                width: 100%;
                max-width: calc(450rem/16);
                margin: 1rem auto 2rem auto;
            }

            .ickonic-button {
                &.is-active {
                    background: linear-gradient(45deg, rgba(229, 174, 44, 1) 0%, rgba(187, 54, 184, 1) 100%);
                    color: white;
                    font-weight: bold;
                    text-shadow: calc(1rem/16) calc(1rem/16) calc(3rem/16) black;
                    &:hover,
                    &:focus,
                    &:active {
                        background: linear-gradient(45deg, rgba(229,174,44,1) 0%, rgba(187,54,184,1) 100%);
                    }
                }
            }
        }

        .user-onboard__gradient-circle {
            position: relative;
            width: calc(70rem/16);
            height: calc(70rem/16);
            margin: 0 auto 2rem auto;
            background: linear-gradient(45deg, rgba(229,174,44,1) 0%, rgba(187,54,184,1) 100%);
            border-radius: 50%;
            overflow: hidden;
            transform: scale(0);
            transition: transform .45s cubic-bezier(0.495, 0.095, 0.200, 1.225) .25s;
            svg {
                position: absolute;
                width: 100%;
                height: 100%;
            }
        }

        .user-onboard__gradient-cover {
            position: absolute;
            top: calc(4rem/16);
            left: calc(4rem/16);
            width: calc(100% - (8rem/16));
            height: calc(100% - (8rem/16));
            background: rgba(14,14,14,1);
            border-radius: 50%;
        }

        .ickonic-button__next-step,
        .ickonic-button__prev-step,
        .ickonic-button__finish-step {
            text-transform: uppercase;
            margin: 1rem 0 0 0;
            font-size: calc(14rem/16);
            font-family: $baseFont;
            box-shadow: none;
            transition: box-shadow .25s $sexyEase, color .25s $sexyEase, background .25s $sexyEase;
            &.is-active {
                box-shadow: inset 0 0 0 0 $sitePrimaryDarkestDesaturated;
                color: $white;
                background: $sitePrimary;
                &:hover,
                &:focus,
                &:active {
                    background: $sitePrimaryLightest;
                }
            }

            &.is-not-active {
                background: $darkerGrey;
                cursor: inherit;
                color: $darkGrey;
            }
        }

        .ickonic-button__finish-step {
            &.is-active {
                color: $black;
                background: $siteAccent;
                &:hover,
                &:focus,
                &:active {
                    background: $siteAccentDark;
                }
            }
        }
    }

    @media screen and (min-width: $breakpointLargeMobile) {
        .user-onboard__wrapper {
            .user-onboard__button-wrapper {
                .ickonic-button {
                    width: calc(120rem/16);
                }
            }
        }
    }

    @media screen and (min-width: $breakpointSmallTablet) {
        .user-onboard__wrapper {
            left: 2rem;
            top: 2rem;
            padding: 2rem;
            width: calc(100% - 4rem);
            .user-onboard__button-wrapper {
                .ickonic-button {
                    width: calc(170rem/16);
                }
            }
        }
    }

    @media screen and (min-width: $breakpointLargeMobile) and (min-height: calc(800em/16)) {
        .user-onboard__wrapper {
            top: 15vh;

        }
    }

    @media screen and (min-width: $breakpointTablet) {
        .user-onboard__wrapper {
            width: calc(696rem/16);
            left: calc(50% - (348rem/16));
            h3 {
                font-size: calc(14rem/16);
            }

            .multi-select-buttons {
                .ickonic-button {
                    padding: calc(7rem/16) calc(25rem/16);
                    margin: 0 1rem 1rem 0;
                    font-size: calc(12rem/16);
                }
            }
        }
    }

    &.is-dark-mode {
        .user-onboard__wrapper {
            background: $darkModeDark;
        }
    }
}
