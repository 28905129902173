.page--watch-video {
    min-height: calc(2300rem/16);
    .video-player {
        &.is-not-theatre-mode {
            @media screen and (min-width: $breakpointSmallTablet) {
                padding: 1rem;
                width: calc(100% - (150rem/16));
            }

            @media screen and (min-width: $breakpointTablet) {
                width: calc(100% - (200rem/16));
            }

            @media screen and (min-width: $breakpointDesktop) {
                width: calc(100% - (250rem/16));
            }
        }
    }

    .page__wrapper {
        position: relative;
        &.is-not-theatre-mode {
            max-width: calc(1777rem/16);
            //padding: 0 calc(200rem/16) 0 0;
            margin: 0 auto;
        }

        .watch__column-info {
            padding: 0 1rem 1rem 1rem;
            //max-width: calc(1000rem/16);
        }

        .watch__column-related {
            padding: 1rem;
            .carousel-landscape__item {
                padding: 0 0 1rem 0;
            }
        }

        @media screen and (min-width: $breakpointSmallTablet) {
            &.is-theatre-mode {
                .watch__column-info {
                    padding: 0 2rem 1rem 1rem;
                }
            }

            .watch__column-related {
                padding: 0 1rem 0 0;
            }
        }

        @media screen and (min-width: $breakpointSmallTablet) {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-wrap: wrap;
            &.is-not-theatre-mode {
                justify-content: flex-start;
                .watch__column-related {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }

            .watch__column-info {
                width: calc(100% - (150rem/16));
                max-width: calc(1527rem/16);
            }

            .watch__column-related {
                width: calc(150rem/16);
            }
        }

        @media screen and (min-width: $breakpointTablet) {
            .watch__column-info {
                width: calc(100% - (200rem/16));
            }

            .watch__column-related {
                width: calc(200rem/16);
            }
        }

        @media screen and (min-width: $breakpointDesktop) {
            .watch__column-info {
                width: calc(100% - (250rem/16));
            }

            .watch__column-related {
                width: calc(250rem/16);
            }
        }

    }
}
