/* Wrapper for the full report section */
.date-range-report-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

/* Top bar container */
.top-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    transition: border 0.3s ease-in-out;
}

/* Highlight top-bar in case of error */
.top-bar-error {
    border: 2px solid #d9534f;
}

/* Date Picker Styling */
.date-range-picker {
    display: flex;
    gap: 16px;
    align-items: center;
}

/* Individual Date Picker Containers */
.date-picker-container {
    display: flex;
    flex-direction: column;
}

/* Labels for Date Pickers */
.date-picker-label {
    font-size: 14px;
    font-weight: 600;
    color: #000 !important;
    margin-bottom: 6px;
}

/* Custom input field styling */
.custom-date-input {
    width: 160px;
    padding: 10px;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    background-color: #ffffff;
    color:  #000 !important; /* ✅ Change text color to black */
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/* Hover and focus states */
.custom-date-input:hover,
.custom-date-input:focus {
    border-color: #6a0dad;
    outline: none;
    box-shadow: 0px 3px 8px rgba(106, 13, 173, 0.3);
}


/* Get Report Button */
.get-report-button {
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 600;
    color: white;
    background-color: #6a0dad;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s ease-in-out, transform 0.2s;
}

.get-report-button:hover {
    background-color: #570a9e;
    transform: scale(1.05);
}

/* Validation Error Message inside top-bar */
.inline-error {
    color: #d9534f;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    min-height: 20px;
    text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
    .top-bar {
        flex-direction: column;
        gap: 10px;
        align-items: center;
    }

    .date-range-picker {
        flex-direction: column;
        align-items: center;
    }

    .get-report-button {
        width: 100%;
    }
}
.cancellation-details-container {
    width: 100%; /* Stays within parent container */
    max-width: 1200px; /* Prevents it from stretching too wide */
    margin: 20px auto; /* Centers the container */
    padding: 15px;
    border-radius: 8px;
    background-color: #ffffff; /* White background */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    color: #333; /* Ensure text is dark */
    overflow-x: auto; /* Allows horizontal scrolling if needed */
}

.cancellation-details-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    color: #000; /* Black text */
}

.cancellation-details-table {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 6px;
    overflow: hidden;
    width: 100%; /* Ensures table spans full width of the container */
}

.cancellation-details-header {
    display: flex;
    background-color: #ff7300; /* Orange header */
    color: #fff; /* White text */
    padding: 10px;
    font-weight: bold;
    width: 100%;
}

.cancellation-details-row {
    display: flex;
    border-bottom: 1px solid #ddd;
    padding: 10px;
    background-color: #f9f9f9; /* Light gray for rows */
    color: #000; /* Ensure text is black */
    width: 100%;
}

.cancellation-details-row:nth-child(odd) {
    background-color: #eeeeee; /* Alternate row background */
}

.cancellation-details-column,
.cancellation-details-cell {
    flex: 1;
    padding: 10px;
    text-align: left;
    color: #000; /* Ensure text is black */
}

.no-data-message {
    text-align: center;
    font-size: 14px;
    color: #666; /* Dark gray */
}

.loading-message {
    text-align: center;
    font-weight: bold;
    color: #ff7300;
}
