.layout--articles-grid {
    .articles-grid__group {
        padding: 1.5rem;
        &.has-no-video-carousels {
            padding: .5rem 1.5rem 1.5rem 1.5rem;
        }
    }

    .articles__video-carousel {
        background: linear-gradient(90deg, $darkestGrey 0%, $darkerGrey 100%);
    }

    .layout--carousel-landscape {
        margin: 1rem auto;
    }

    .articles-grid__cat-title-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 1.5rem 1.5rem 1.5rem;
        width: 100%;
        max-width: calc(1805rem/16);
        margin: 0 auto;
        .ickonic-button {
            margin: 0 0 0 calc(10rem/16);
            padding: calc(1rem/16) calc(10rem/16);
        }
    }

    .articles-grid__category-title {
        color: $black;
        margin: 0;
    }

    & > h2 {
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: calc(1rem/16);
        font-size: calc(18rem/16);
        color: $black;
        text-align: center;
        margin: 3rem 0 1rem 0;
        padding: 0 1rem 0 0;
    }

    .articles-grid__ad {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .articles-grid__article,
    .articles-grid__ad {
        margin: 0 0 2rem 0;
        background: $lighterGrey;
        position: relative;
        overflow: hidden;
        border-radius: calc(12rem/16);
        box-shadow: 0 0 calc(4rem/16) 0 rgba(0,0,0,0.3);
        &.loader--side-to-side {
            min-height: calc(200rem/16);
            background: $lighterGrey;
            &:after {
                background: darken($lighterGrey, 2%);
                background: linear-gradient(104deg, rgba(0,0,0,0) 20%, darken($lighterGrey, 2%) 50%, rgba(0,0,0,0) 80%);
            }
        }

        &:last-child {
            margin: 0;
        }

        .articles-grid__thumbnail {
            position: relative;
            width: 100%;
            height: calc(130rem/16);
            margin: 0;
        }

        .articles-grid__content {
            padding: 1rem 1rem 3rem 1rem;
            line-height: 1.3;
            & > a {
                color: $black;
                font-size: calc(14rem/16);
                font-weight: 600;
                &:hover,
                &:focus,
                &:active {
                    text-decoration: underline;
                }
            }

            p {
                margin: calc(10rem/16) 0 0 0;
                font-size: calc(12rem/16);
                position: absolute;
                bottom: 1rem;
                left: 1rem;
                width: calc(100% - 2rem);
                a {
                    color: $darkGrey;
                }
            }
        }
    }

    .articles-grid__load-more {
        display: flex;
        justify-content: center;
        align-items: center;
        button {
            cursor: pointer;
            border: none;
            outline: none;
            background: none;
            color: $black;
            text-transform: uppercase;
            font-weight: bold;
            font-size: calc(16rem/16);
            padding: 1rem;
            transition: transform .35s $sexyEase;
            svg {
                display: block;
                margin: 0 auto;
                width: calc(30rem/16);
                height: calc(30rem/16);
                transition: transform .35s $sexyEase;
                &.is-loading {
                    animation: spin .5s infinite linear;
                    padding: calc(5rem/16);
                }
            }

            &:hover,
            &:focus,
            &:active {
                transform: translateY(calc(-5rem/16));
                svg {
                    transform: translateY(calc(10rem/16));
                }
            }
        }
    }

    @media screen and (min-width: $breakpointTablet) {
        .articles-grid__group {
            padding: 2rem 0 0 2rem;
            display: flex;
            align-items: stretch;
            flex-wrap: wrap;
            max-width: calc(1805rem/16);
            margin: 0 auto;
            &.has-no-video-carousels {
                padding: 0 0 0 2rem;
            }

            &:nth-child(n+19) {
                padding: 0 0 0 2rem;
            }
        }

        .articles-grid__cat-title-wrapper {
            padding: 1.5rem 2rem;
        }

        & > h2 {
            padding: 0 2rem 0 0;
        }

        .layout--carousel-landscape {
            padding: 1.5rem 0.5rem 0.5rem 0.5rem;
        }

        .articles-grid__article,
        .articles-grid__ad {
            width: calc(50% - (32rem/16));
            margin: 0 2rem 2rem 0;
            &:last-child {
                margin: 0 2rem 2rem 0;
            }

            .articles-grid__content {
                & > a {
                    font-size: calc(16rem/16);
                    line-height: 1.4;
                }

                p {
                    font-size: calc(12rem/16);
                }
            }
        }
    }

    @media screen and (min-width: $breakpointDesktop) {
        .articles-grid__article,
        .articles-grid__ad {
            width: calc(33.333333% - (32rem/16));
            &:first-child {
                display: flex;
                width: calc(100% - (32rem/16));
                min-height: calc(200rem/16);
                .articles-grid__thumbnail {
                    order: 1;
                    width: 70%;
                    height: 100%;
                }

                .articles-grid__content {
                    width: 30%;
                    & > a {
                        font-size: calc(22rem/16);
                    }
                }
            }

            .articles-grid__content {
                & > a {
                    font-size: calc(20rem/16);
                }
            }
        }
    }

    @media screen and (min-width: $breakpointWide) {
        .articles-grid__article,
        .articles-grid__ad {
            width: calc(25% - (32rem/16));
            &:first-child {
                width: calc(75% - (32rem/16));
            }
        }
    }
}
