.ickonic-dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
    .ickonic-dropdown__selected {
        border-radius: calc(10rem/16);
        background-color: $darkModeMid;
        padding: calc(10rem/16) calc(20rem/16) calc(10rem/16) calc(10rem/16);
        cursor: pointer;
        display: flex;
        font-size: calc(14rem/16);
        justify-content: space-between;
        align-items: center;
        &.is-open {
            border-top-left-radius: calc(10rem/16);
            border-top-right-radius: calc(10rem/16);
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .ickonic-dropdown__arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(13rem/16);
        height: calc(13rem/16);
        font-size: calc(12rem/16);
        transition: transform 0.3s ease;
    }

    .ickonic-dropdown__arrow.is-open {
        transform: rotate(180deg);
    }

    .ickonic-dropdown__options {
        width: 100%;
        background-color: $darkModeMid;
        max-height: calc(200rem/16);
        overflow-y: auto;
        box-shadow: 0 calc(4rem/16) calc(8rem/16) rgba(0, 0, 0, 0.1);
        z-index: 1000;
        border-bottom-left-radius: calc(10rem/16);
        border-bottom-right-radius: calc(10rem/16);
        &.is-absolute-positioned {
            position: absolute;
            top: 100%;
            left: 0;
        }
    }

    .ickonic-dropdown__option {
        padding: calc(10rem/16);
        font-size: calc(14rem/16);
        cursor: pointer;
        transition: background-color 0.2s;
    }

    .ickonic-dropdown__option:hover {
        background-color: $darkModeLight;
    }

    .ickonic-dropdown__option.is-selected {
        background-color: $darkModeLight;
        font-weight: bold;
    }
}


