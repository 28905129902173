.video-player {
    outline: none;
    border: none;
    width: 100%;
    margin: 0 0 1rem 0;
    &.is-not-theatre-mode {
        @media screen and (min-width: $breakpointSmallTablet) {
            margin: 0;
            .video-player__inner {
                &.is-not-full-screen {
                    border-radius: calc(16rem/16);
                }
            }
        }
    }

    .video-player__cover-overlay {
        z-index: 3;
        transition: transform 0s $sexyEase .35s;
        &.is-hidden {
            transform: translateY(-100%);
        }
    }

    .loader--side-to-side {
        position: absolute;
        z-index: 5;
    }
}

.video-player__inner {
    min-height: calc(200rem/16);
    flex-basis: 100%;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0);
    margin: 0 auto;
    max-height: calc(-10.625rem + 100vh);
    .ickonic-video-player {
        height: 100% !important;
        width: 100% !important;
        padding-top: 0 !important;
        video {
            outline: none;
        }
    }

    .video-player__video-controls {
        position: absolute;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        bottom: -100%;
        left: 0;
        width: 100%;
        padding: calc(16rem/16) 0;
        background: linear-gradient(0deg, rgba(0,0,0,0.8) 50%, rgba(0,0,0,0) 100%);
        opacity: 0;
        z-index: 2;
        transition: opacity 1s $sexyEase, bottom 0s linear 1s;
        &.is-not-hidden {
            transition: opacity .25s $sexyEase, bottom 0s linear;
            opacity: 1;
            bottom: 0;
        }

        .video-controls__shelf {
            transform: translateY(calc(-5rem/16));
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            padding: 0 calc(16rem/16) 0 calc(13rem/16);
            .video-controls__left-side {
                margin: 0 auto 0 0;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
        }
    }
}

.video-controls__button {
    cursor: pointer;
    width: calc(36rem/16);
    height: calc(36rem/16);

    padding: 0;
    background: none;
    outline: none;
    border: 0;
    box-shadow: none !important;
    svg {
        width: 100%;
        height: 100%;
        padding: calc(10rem/16);
        opacity: 0.7;
        transition: opacity .1s $sexyEase;
        * {
            fill: $white;
        }
    }

    &:hover {
        svg {
            opacity: 1;
        }
    }
}
