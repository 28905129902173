.select__custom-clear-indicator {
    display: flex;
    cursor: pointer;
    margin: 0 calc(5rem/16) 0 0;
    svg {
        width: calc(24rem/16);
        height: calc(24rem/16);
        padding: calc(4rem/16);
        * {
            fill: $white;
        }
    }
}
