.menu__slideout {
    position: fixed;
    //box-shadow: inset 0 calc(1rem/16) 0 0 rgba(0,0,0,0.075);
    top: $headerHeight;
    left: -100%;
    width: 100%;
    height: calc(105vh - $headerHeight);
    background: $white;
    transition: transform .45s $sexyEase;
    padding-bottom: 5vh;
    box-shadow: 0 0 calc(3rem/16) rgba(0,0,0,0.2);
    &.has-notification-bar {
        top: $headerHeight + $notifyBarHeight;
    }

    .menu__inner {
        &.menu-is-open {
            z-index: 5;
        }
    }

    .tooltip-only {
        width: $headerHeight;
        z-index: 4;
        max-width: calc(300rem/16);
        position: absolute;
        top: calc(10rem/16);
        left: calc(100% - (48rem/16));
        opacity: 1;
        transform: translateX(0);
        transition: opacity .45s $sexyEase, transform 0s linear 0s;
        &.menu-is-open {
            transform: translateX(200%);
            opacity: 0;
            transition: opacity .45s $sexyEase, transform 0s linear .45s;
        }

        a {
            padding: 0;
            p {
                width: max-content;
                max-width: calc(300rem/16);
                position: absolute;
                transform: translateY(calc(4rem/16)) translateX(calc(-300rem/16));
                transition: transform 0 linear .25s, opacity .15s $sexyEase;
                opacity: 0;
                left: calc(100% + calc(16rem/16));
                background: $white;
                padding: calc(4rem/16) calc(12rem/16);
                border-radius: calc(4rem/16);
                box-shadow: 0 0 calc(1rem/16) calc(2rem/16) rgba(0,0,0,0.05);
                &:before {
                    position: absolute;
                    content: '';
                    left: calc(-5rem/16);
                    top: calc(50% - calc(5rem/16));
                    width: calc(10rem/16);
                    height: calc(10rem/16);
                    transform: rotate(45deg);
                    background: $white;
                    box-shadow: 0 0 calc(1rem/16) calc(2rem/16) rgba(0,0,0,0.05);
                }

                &:after {
                    position: absolute;
                    content: '';
                    left: 0;
                    top: 0;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    background: $white;
                    border-radius: calc(4rem/16);
                }

                svg {
                    transform: translateY(calc(1rem/16));
                }

                span {
                    position: relative;
                    z-index: 2;
                }
            }

            &:hover,
            &:active,
            &:focus {
                p {
                    transform: translateY(calc(4rem/16)) translateX(calc(-10rem/16));
                    transition: transform 0s linear 0s, opacity .15s $sexyEase;
                    opacity: 1;
                }
            }

            svg {
                position: relative;
                z-index: 2;
                background: $white;
            }
        }

        .icon--decorative {
            height: calc(44rem/16);
            width: calc(44rem/16);
            margin: 0;
            padding: calc(11rem/16);
        }
    }

    .menu__item {
        position: relative;
        min-height: calc(44rem/16);
        .menu-item__tooltip {
            display: none;
            position: absolute;
            right: -2rem;
            top: 0;
        }
    }

    &.is-dark-mode {
        background: $darkModeLight;
        box-shadow: 0 0 calc(3rem/16) calc(2rem/16) rgba(0,0,0,0.2);
        ul {
            &.tooltip-only {
                a {
                    svg {
                        background: $darkModeLight;
                    }

                    p {
                        background: lighten($darkModeLight, 5%);
                        &:before {
                            background: lighten($darkModeLight, 5%);
                        }

                        &:after {
                            background: lighten($darkModeLight, 5%);
                        }

                        svg {
                            background: lighten($darkModeLight, 5%);
                        }
                    }
                }
            }
        }

        .menu__inner {
            &.menu-is-open {
                li {
                    a {
                        &:hover,
                        &:focus,
                        &:active {
                            background: rgba(210, 228, 255, 0.04);
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: $breakpointSmallMobile) {
    .menu__slideout {
        left: calc(-300rem/16);
        width: calc(300rem/16);
    }
}
