.layout--presenter-showcase {
    background: #010107;
    padding: 2rem 0 8rem 0;
    h1 {
        font-family: $tertiaryFont;
        text-transform: uppercase;
        font-weight: bold;
        padding: 0 1rem;
        text-align: center;
        span {
            display: inline-block;
            background: linear-gradient(180deg, #4676E6 0%, #b760b4 100%);
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
        }

        @media screen and (min-width: $breakpointLargeMobile) {
            font-size: calc(35rem/16);
        }

        @media screen and (min-width: $breakpointDesktop) {
            font-size: calc(40rem/16);
        }

        @media screen and (min-width: $breakpointWide) {
            font-size: calc(45rem/16);
        }
    }

    .presenter-showcase__inner {
        overflow-x: auto;
    }

    .presenter-showcase__track {
        width: calc(1066rem/16);
        margin: 0 auto;
        padding: calc(8rem/16);
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        .presenter-showcase__presenter {
            width: calc(175rem/16);
            height: calc(370rem/16);
            margin: 0;
            padding: calc(2rem/16);
            border-radius: calc(20rem/16);
            transition: width .35s $sexyEase;
            .presenter-showcase__presenter-inner {
                width: calc(100% - (4rem/16));
                height: calc(100% - (4rem/16));
                left: calc(2rem/16);
                top: calc(2rem/16);
                overflow: hidden;
                background: rgb(54,54,99);
                background: radial-gradient(circle, rgba(54,54,99,1) 0%, rgba(26,18,33,1) 100%);
            }

            .presenter-showcase__speaker-img {
                z-index: 0;
                width: calc(225rem/16);
                height: calc(430rem/16);
                left: calc(-25rem/16);
                top: calc(-50rem/16);
                transition: left .35s $sexyEase;
            }

            &.is-hovered {
                width: calc(205rem/16);
                .presenter-showcase__speaker-img {
                    left: calc(-13rem/16);
                }
            }

            &.sibling-has-hover {
                width: calc(172rem/16);
            }
        }
    }

    .presenter-showcase__content {
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 1rem;
        h3,
        p {
            text-align: center;
        }

        h3 {
            font-size: calc(14rem/16);
            color: $white;
            font-family: $tertiaryFont;
            margin: 0 0 calc(5rem/16) 0;
        }

        p {
            text-transform: uppercase;
            font-size: calc(12rem/16);
            color: $lightGrey;
            margin: 0;
        }
    }

    .presenter-showcase__gradient-overlay {
        top: unset;
        bottom: 0;
        height: calc(100rem/16);
        background: rgb(51,0,51);
        background: linear-gradient(0deg, rgba(51,0,51,1) 0%, rgba(0,0,0,0) 100%);
    }
}
