/* Wrapper for the full report section */
.date-range-report-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

/* Top bar container */
.top-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    transition: border 0.3s ease-in-out;
}

/* Highlight top-bar in case of error */
.top-bar-error {
    border: 2px solid #d9534f; /* Red border for validation */
}

/* Date Picker Styling */
.date-range-picker {
    display: flex;
    gap: 16px;
    align-items: center;
}

/* Individual Date Picker Containers */
.date-picker-container {
    display: flex;
    flex-direction: column;
}

/* Labels for Date Pickers */
.date-picker-label {
    font-size: 14px;
    font-weight: 600;
    color: #000 !important;
    margin-bottom: 6px;
}

/* Custom input field styling */
.custom-date-input {
    width: 160px;
    padding: 10px;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    background-color: #ffffff;
    color: #6a0dad;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/* Hover and focus states */
.custom-date-input:hover,
.custom-date-input:focus {
    border-color: #6a0dad;
    outline: none;
    box-shadow: 0px 3px 8px rgba(106, 13, 173, 0.3);
}

/* Get Report Button */
.get-report-button {
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 600;
    color: white;
    background-color: #6a0dad;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s ease-in-out, transform 0.2s;
}

.get-report-button:hover {
    background-color: #570a9e;
    transform: scale(1.05);
}

/* Validation Error Message inside top-bar */
.error-message {
    color: #d9534f;
    font-size: 14px;
    font-weight: 500;
    margin-top: -8px;
    text-align: center;
    background: rgba(217, 83, 79, 0.1);
    border-radius: 4px;
    padding: 5px;
    width: fit-content;
    align-self: center;
}

/* Wraps error message and button together */
.report-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 60px; /* Prevents movement when error appears */
}

/* Ensures error message does not push the button */
.inline-error {
    color: #d9534f;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    min-height: 20px; /* Reserves space even when no error is shown */
    text-align: center;
}


/* Responsive Design */
@media (max-width: 768px) {
    .top-bar {
        flex-direction: column;
        gap: 10px;
        align-items: center;
    }

    .date-range-picker {
        flex-direction: column;
        align-items: center;
    }

    .get-report-button {
        width: 100%;
    }

    .error-message {
        width: 90%;
        text-align: center;
    }
}
