.ickonic-button--pill {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: $darkGrey;
    border-radius: calc(25rem/16);
    margin: 0 auto;
    font-weight: 700;
    width: auto;
    padding: calc(5rem/16) calc(15rem/16);
    color: $white;
    font-family: $baseFont;
    text-align: center;
    .ickonic-button__inner {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            font-size: calc(14rem/16);
        }

        svg {
            width: calc(24rem/16);
            height: calc(24rem/16);
            margin: 0 0 0 calc(6rem/16);
            * {
                fill: $white;
            }
        }
    }

    &:hover,
    &:active,
    &:visited {
        box-shadow: none;
        outline: none;
        color: $white;
    }
}
