.global__notification-bar {
    height: $notifyBarHeight;
    padding: 0 calc(5rem/16);
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $blueToPurpleGradient;
    z-index: 999;
    &.is-dark-mode {
        //background: $darkestGrey;
        p {
            //color: $white;
        }
    }

    .u-full_cover_absolute {
        &:after {
            animation-name: sideToSideLoading;
            animation-duration: 6s;
            animation-iteration-count: infinite;
            animation-timing-function: cubic-bezier(.56,.15,.16,1);
            content: '';
            background: $lighterGrey;
            background: linear-gradient(104deg, rgba(0,0,0,0) 20%, rgba(255,255,255,0.25) 50%, rgba(0,0,0,0) 80%);
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
        }
    }

    .notification-bar__content {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 2;
    }

    p {
        margin: 0 calc(10rem/16) 0 0;
        font-size: calc(10rem/16);
        font-family: $baseFont;
        font-weight: 600;
        line-height: 1;
        color: $white;
        text-transform: uppercase;
        position: relative;
        text-shadow: calc(0rem/16) calc(0rem/16) calc(5rem/16) rgba(0, 0, 0, 1);
    }

    .ickonic-button {
        padding: calc(2rem/16) calc(10rem/16) !important;
        width: auto !important;
        height: auto !important;
        margin: 0;

        white-space: nowrap;
        line-height: 1 !important;
        background: $white !important;
        color: $black !important;
        span {
            font-weight: 600;
            letter-spacing: calc(-0.5rem/16);
            font-size: calc(10rem/16) !important;
            text-transform: uppercase;
        }

        &:hover,
        &:focus,
        &:active {
            background: $lighterGrey !important;
        }
    }

    @media screen and (min-width: $breakpointSmallTablet) {
        p {
            font-size: calc(12rem/16);
        }

        .ickonic-button {
            span {
                font-size: calc(12rem / 16) !important;
            }
        }
    }
    //
    //@media screen and (min-width: $breakpointSmallTablet) {
    //    p {
    //        font-size: calc(14rem/16);
    //    }
    //}
}
