.page--no-nav {
    padding-top: 0;
    .page__header {
        height: $headerHeight;
        width: 100%;
        box-shadow: 0 0 calc(2rem/16) rgba(0,0,0,0.2);
        .ickonic-logo--primary {
            margin: 0 auto;
            display: block;
            width: 100%;
            height: 100%;
            padding: calc(12rem/16);
            g {
                * {
                    fill: $black;
                }
            }

            & > circle {
                stroke: $black;
            }
        }
    }

    &.is-dark-mode {
        background: $bodyBGColor;

        .page__header {
            background: $bodyBGColor;
            box-shadow: 0 0 calc(3rem / 16) calc(2rem / 16) rgba(0, 0, 0, 0.3);

            .ickonic-logo--primary {
                g {
                    * {
                        fill: $white;
                    }
                }

                & > circle {
                    stroke: $white;
                }
            }
        }
    }
}
