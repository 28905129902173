.header--global {
    background: $darkModeLight;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    height: $headerHeight;
    z-index: 999;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &.has-notification-bar {
        top: $notifyBarHeight;
    }

    .header__cover {
        z-index: 1;
        background: $darkModeLight;
        box-shadow: 0 0 calc(3rem/16) calc(2rem/16) rgba(0,0,0,0.2);
    }

    .header__logo {
        height: $headerHeight;
        width: calc(120rem/16);
        margin: 0 auto;
        display: block;
        position: absolute;
        top: 0;
        left: calc(45rem/16);
        z-index: 6;
        svg {
            width: 100%;
            height: 100%;
            padding: calc(12rem/16);
            & > circle {
                transition: stroke $darkModeTransitionTimer $sexyEase;
                stroke: $white;
            }
        }

        g {
            * {
                transition: fill $darkModeTransitionTimer $sexyEase;
                fill: $white;
            }
        }
    }

    button,
    a {
        .u-full_cover_absolute {
            border-radius: calc(16rem / 16);
        }
    }

    .ickonic-button {
        z-index: 5;
        padding: calc(2rem/16) calc(15rem/16);
        background: $sitePrimaryLight;
        color: $white;
        text-shadow: calc(2rem/16) calc(2rem/16) calc(2rem/16) rgba(0, 0, 0, 0.3);
        font-family: $secondaryFont;
        animation: boxShadowGlowPulsePink 5s infinite $sexyEase;
        border-radius: calc(18rem/16);
        margin: 0 0 0 auto;
        &:hover,
        &:focus,
        &:active {
            transform: translateY(0);
        }
    }

    .header__subscribe {
        position: absolute;
        top: 0;
        right: calc(50rem/16);
        height: $headerHeight;
        padding: calc(9rem/16) 0 calc(9rem/16) calc(9rem/16);
        background: $darkerGrey;
        width: calc(200rem/16);
        z-index: 5;

    }

    &.has-desktop-menu {
        .header__logo {
            left: 0;
        }

        .button--burger {
            display: none !important;
        }
    }

    @media screen and (min-width: $breakpointSmallTablet) {
        .header__subscribe {
            right: calc(125rem/16);
        }
    }
}
