.ickonic-button--gradient {
    transition: background .25s $sexyEase, transform .35s $sexyEase;
    margin: 0 auto;
    font-weight: bold;
    border-radius: calc(24rem/16);
    padding: calc(10rem/16) calc(40rem/16);
    text-align: center;
    .ickonic-button__inner {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    span,
    svg {
        position: relative;
        z-index: 2;
    }

    .u-full_cover_absolute {
        &:first-of-type {
            opacity: 0;
            transition: opacity 0s linear .175s;
        }

        &:last-of-type {
            z-index: 1;
            transition: opacity .175s $sexyEase;
        }
    }

    &:hover,
    &:focus,
    &:active {
        .u-full_cover_absolute {
            &:first-of-type {
                opacity: 1;
                transition: opacity 0s linear;
            }

            &:last-of-type {
                opacity: 0;
            }
        }
    }
}
