.loader--side-to-side {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: lighten($lightestGrey, 4%);
}

.loader--side-to-side:after {
    animation-name: sideToSideLoading;
    animation-duration: 1200ms;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(.56,.15,.16,1);
    content: '';
    background: $lighterGrey;
    background: linear-gradient(104deg, rgba(0,0,0,0) 20%, $lighterGrey 50%, rgba(0,0,0,0) 80%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

.is-dark-mode {
    .loader--side-to-side {
        background: #202020;
    }

    .loader--side-to-side:after {
        background: #252525;
        background: linear-gradient(104deg, rgba(0,0,0,0) 20%, #252525 50%, rgba(0,0,0,0) 80%);
    }
}

.is-light-mode {
    .loader--side-to-side {
        background: $lighterGrey;
    }

    .loader--side-to-side:after {
        background: darken($lighterGrey, 2%);
        background: linear-gradient(104deg, rgba(0,0,0,0) 20%, darken($lighterGrey, 2%) 50%, rgba(0,0,0,0) 80%);
    }
}
