.popup--basic {
    .layout--plan-selector {
        padding: 2rem;
        background: darken($darkerGrey, 4%);
        border-radius: calc(16rem/16);
        max-width: calc(800rem/16);
    }
}

.layout--plan-selector {
    margin: 0;
    width: 100%;
    label {
        display: block;
        width: 100%;
        padding: calc(5rem/16);
        color: $siteError;
    }

    .form__plan-single {
        cursor: pointer;
        position: relative;
        padding: calc(24rem/16);
        border-radius: calc(10rem/16);
        box-shadow: inset 0 0 0 calc(2rem/16) $lighterGrey;
        margin: 0 0 2rem 0;
        transition: box-shadow .2s $sexyEase, opacity .2s $sexyEase;
        &:last-of-type {
            margin: 0;
            svg {
                * {
                    fill: $siteAccent;
                }
            }
        }

        .plan-single__label,
        h2,
        h3,
        span {
            transition: color .2s $sexyEase;
        }

        svg {
            position: absolute;
            top: calc(10rem/16);
            right: calc(10rem/16);
            width: calc(18rem/16);
            height: calc(18rem/16);
            * {
                fill: $sitePrimaryLightest;
            }
        }

        .plan-single__label {
            background: $white;
            position: absolute;
            top: calc(-12rem/16);
            left: calc(50% - (60rem/16));
            width: calc(120rem/16);
            text-align: center;
            text-transform: uppercase;
            margin: 0;
            font-weight: bold;
            padding: calc(2rem/16);
            box-shadow: inset 0 0 0 calc(2rem/16) $lighterGrey;
            font-size: calc(12rem/16);
            border-radius: calc(5rem/16);
            transition: box-shadow .2s $sexyEase;
            span {
                position: absolute;
                bottom: 0;
                left: 0;
                box-shadow: inset 0 0 0 calc(2rem/16) $siteAccent;
                background: $white;
                text-transform: uppercase;
                font-weight: bold;
                font-family: $tertiaryFont;
                font-size: calc(12rem/16);
                text-align: center;
                padding: calc(2rem/16) 0 0 0;
                border-top-left-radius: calc(5rem/16);
                border-top-right-radius: calc(5rem/16);
                transition: opacity .2s $sexyEase;
                opacity: 0;
                z-index: 1;
            }
        }

        .plan-single__flag {
            top: calc(35rem/16);
            left: unset;
            right: calc(2rem/16);
            overflow: hidden;
            height: calc(20rem/16);
            width: calc(100rem/16);
            padding: 0 0 0 calc(10rem/16);
            span {
                left: unset;
                right: 0;
                width: calc(100% - (10rem/16));
                background: #e8bb27;
                color: black;
                text-transform: uppercase;
                font-weight: bold;
                font-family: "Outfit", Helvetica sans-serif;
                font-size: calc(12rem/16);
                text-align: center;
                line-height: 1.4;
                padding: calc(2rem/16) 0 0 0;
                transition: opacity .2s cubic-bezier(.84,0,.13,.98);
                z-index: 1;
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    left: calc(-8rem/16);
                    height: calc(16rem/16);
                    width: calc(16rem/16);
                    transform: rotate(45deg);
                    background: #e8bb27
                }

                &:before {
                    top: calc(-8rem/16);
                }

                &:after {
                    bottom: calc(-8rem/16);
                }
            }
        }

        h2 {
            font-family: $secondaryFont;
            font-weight: 500;
            font-size: calc(24rem/16);
            margin: calc(8rem/16) 0 calc(12rem/16) 0;

        }

        h3 {
            font-family: $tertiaryFont;
            margin: 0 0 calc(16rem/16) 0;
            font-size: calc(24rem/16);
            span {
                font-weight: bold;
                &:first-of-type {
                    color: $sitePrimaryLightest;
                }

                &:last-of-type {
                    display: block;
                    font-weight: normal;
                    font-size: calc(12rem/16);
                    text-transform: uppercase;
                    margin: calc(5rem/16) 0 0 0;
                }
            }
        }

        ul {
            margin: 1.25rem 0 0 0;
        }

        .plan-single__feature {
            font-size: calc(14rem/16);
            margin: 0 0 calc(5rem/16) 0;
            font-family: $baseFont;
            &:last-of-type {
                margin: 0;
            }
        }

        &.plan-selected {
            &.is-not-selected {
                opacity: 0.6;
            }
        }
    }

    .error-message {
        text-align: center;
    }

    @media screen and (min-width: $breakpointSmallTablet) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .form__plan-single {
            width: calc(50% - (4rem/16));
            margin: 0;
        }
    }

    &.is-dark-mode.is-not-loading,
    &.has-light-mode.is-not-loading {
        .form__plan-single {
            &:hover,
            &:focus,
            &:active,
            &.is-selected {
                box-shadow: inset 0 0 0 calc(2rem/16) $sitePrimaryLightest;
                .plan-single__label {
                    box-shadow: inset 0 0 0 calc(2rem/16) $sitePrimaryLightest;
                }

                &:last-of-type {
                    box-shadow: inset 0 0 0 calc(2rem/16) $siteAccent;
                    .plan-single__label {
                        box-shadow: inset 0 0 0 calc(2rem/16) $siteAccent;
                    }
                }
            }

            &:hover,
            &:focus,
            &:active {
                opacity: 1;
                &:last-of-type {
                    .plan-single__label {
                        span {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    &.is-dark-mode {
        .form__plan-single {
            box-shadow: inset 0 0 0 calc(2rem / 16) $darkModeLighter;
            .plan-single__label {
                background: $darkModeDark;
                box-shadow: inset 0 0 0 calc(2rem / 16) $darkModeLighter;
                span {
                    background: $darkModeDark;
                }
            }
        }
    }

    &.is-loading {
        .form__plan-single {
            &.u-relative_hidden {
                //background: $white;
                height: calc(240rem/16);
                > div {
                    //animation: sideToSideLoading 2s infinite;
                }

                &.is-dark-mode {
                    background: $darkModeLighter;
                }
            }
        }
    }
}
