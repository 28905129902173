.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: background .45s $sexyEase;
    &.is-dark-mode {
        .popup__content {
            background: $darkModeDark;
        }
    }

    &.is-transitioning-in {
        background-color: rgba(0, 0, 0, 0.9);
        transition: background .45s $sexyEase .01s;
    }
}
