.cancel-or-pause__step-provide-feedback {
    .step-provide-feedback__next-step {
        .ickonic-button {
            display: table;
            margin: 2rem auto 0 auto;



        }
    }
}
