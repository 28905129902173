.layout--carousel-cards-with-content {
    padding: 1rem;
    background: #03031c;
    background: linear-gradient(180deg, #03031c 0%, #010107 100%);
    .carousel-cards-with-content__wrapper,
    .carousel-cards-with-content__content {
        max-width: calc(1200rem/16);
        margin: 0 auto;
        text-align: center;
    }

    .carousel-cards-with-content__wrapper {
        position: relative;
        width: 100%;
    }

    .carousel-cards-with-content__content {
        max-width: calc(440rem/16);
        padding: 1rem;
        p {
            font-size: calc(14rem/16);
        }

        @media screen and (min-width: $breakpointSmallTablet) {
            max-width: calc(600rem/16);
        }

        @media screen and (min-width: $breakpointTablet) {
            padding: 1rem 1rem 3rem 1rem;
        }
    }

    h1 {
        font-family: $tertiaryFont;
        text-transform: uppercase;
        font-weight: bold;
        span {
            background: linear-gradient(180deg, #4676E6 0%, #b760b4 100%);
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
        }

        @media screen and (min-width: $breakpointLargeMobile) {
            font-size: calc(35rem/16);
        }

        @media screen and (min-width: $breakpointDesktop) {
            font-size: calc(40rem/16);
        }

        @media screen and (min-width: $breakpointWide) {
            font-size: calc(45rem/16);
        }
    }

    @media screen and (min-width: $breakpointSmallTablet) {
        padding: 1rem 0.5rem 4rem 0.5rem;
    }
}

.carousel-cards-with-content__inner {
    position: relative;
    z-index: 1;
    width: 100%;
    @media screen and (min-width: $breakpointSmallTablet) {
        width: calc(100% - 6rem);
        margin: 0 3rem;
        overflow: hidden;
    }

    @media screen and (min-width: $breakpointSuperWide) {
        width: calc(100% - 8rem);
        margin: 0 4rem;
    }
}

.carousel-cards-with-content__track {
    display: flex;
    transition: transform 0.45s $sexyEase;
    will-change: transform;
}

.carousel-cards-with-content__item {
    flex: 0 0 auto;
    padding: 0.5rem; // calc(8rem / 16)
    position: relative; // Ensure label positioning works
    border-radius: calc(20rem/16);
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: calc(8rem/16);
        width: calc(100% - 1rem);
        height: 80%;
        background: $siteSecondaryDarkest;
        border-bottom-right-radius: calc(20rem/16);
        border-bottom-left-radius: calc(20rem/16);
    }

    .carousel-cards-with-content__item-free-view {
        top: calc(2rem/16);

        z-index: 2;
    }

    .carousel-cards-with-content__item-image {
        border-top-left-radius: calc(20rem/16);
        border-top-right-radius: calc(20rem/16);
        .carousel-cards-with-content__item-image-gradient {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: calc(10rem/16);
            background: $siteSecondaryDarkest;
            background: linear-gradient(0deg, $siteSecondaryDarkest 0%, rgba(32,32,32,0) 100%);
        }
    }

    .carousel-cards-with-content__thumbnail {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: transform 0.3s ease;
    }

    &:hover .carousel-cards-with-content__thumbnail {
        transform: scale(1.05);
    }

    label {
        position: absolute;
        bottom: 0.5rem;
        left: 50%;
        transform: translateX(-50%);
        padding: 0.3125rem 0.625rem; // 5rem / 16 = 0.3125rem, 10rem / 16 = 0.625rem
        background: rgba(128, 128, 128, 0.8); // Replace $midGrey with actual color
        border-radius: 0.5rem; // 8rem / 16 = 0.5rem
        font-family: 'Arial', sans-serif; // Replace $secondaryFont with actual font if needed
        text-transform: uppercase;
        font-size: 0.875rem;
        color: #fff;
        white-space: nowrap;
    }

    .carousel-cards-with-content__item-content {
        padding: 0 1rem 2rem 1rem;
        position: relative;
        z-index: 1;
        h3 {
            margin: 0;
        }

        h3,
        p {
            transform: translateY(-1rem);
        }

        p {
            margin-top: calc(7rem/16);
            text-transform: uppercase;
            color: $lightGrey;
            font-size: calc(14rem/16);
        }
    }

    .ickonic-button {
        text-transform: uppercase;
        width: auto;
        &.ickonic-button--pill {
            background: $white;
            color: $black;
            font-weight: 500;
            width: auto;
            margin: 0 auto;
            height: auto;
            display: table;
            font-size: calc(12rem/16);
            svg {
                transform: rotate(180deg);
                * {
                    fill: $black;
                }
            }

            &:hover,
            &:focus,
            &:active {
                background: $lighterGrey;
            }
        }
    }

    @media screen and (min-width: $breakpointSmallTablet) {
        .carousel-cards-with-content__item-content {
            padding: 0 1rem 3rem 1rem;
        }
    }

    @media screen and (min-width: $breakpointDesktop) {
        padding: 1rem;
        &:after {
            width: calc(100% - 2rem);
            left: 1rem;
        }

        .carousel-cards-with-content__item-content {
            h3 {
                font-size: calc(20rem/16);
            }
        }

        .ickonic-button {
            width: auto;
            &.ickonic-button--pill {
                font-size: calc(14rem/16);
                padding: calc(10rem/16) calc(20rem/16);
            }
        }

        .carousel-cards-with-content__item-free-view {
            padding: calc(5rem/16);
            top: calc(2rem/16);
        }
    }
}

.carousel-cards-with-content__controls {
    display: none;

    @media screen and (min-width: $breakpointSmallTablet) {
        position: absolute;
        display: flex;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        justify-content: space-between;
    }

    .carousel-cards-with-content__button {
        width: 3rem; // calc(48rem / 16) = 3rem
        height: 3rem;
        background: rgba(51, 51, 51, 0.5); // Replace $darkGrey with actual color
        border: none;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background 0.3s ease;

        &:hover {
            background: rgba(51, 51, 51, 0.8); // Replace $darkGrey with actual color
        }

        svg {
            width: 60%;
            height: 60%;
            fill: #fff;
        }
    }
}
