.page--sign-up {
    padding: 0 0 1rem 0;
    min-height: 100vh;
    background: $white;
    .page__inner {
        &.is-loading {
            filter: blur(calc(10rem/16));
        }
    }

    .form--sign-up {
        padding: 1rem;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        max-width: calc(550rem/16);
        h1 {
            text-align: left;
            font-family: $tertiaryFont;
            font-weight: 600;
            font-size: calc(24rem/16);
            margin: 0 0 1rem 0;
        }

        > h2 {
            font-size: calc(20rem/16);
            font-weight: 400;
            margin: 0 0 2rem 0;
        }

        .sign-up__login-switch {
            margin: 1rem 0 calc(8rem/16) 0;
            font-size: calc(12rem/16);
            a {
                text-decoration: none;
                color: $sitePrimary;
                &:hover,
                &:focus,
                &:active {
                    text-decoration: underline;
                }
            }
        }

        .form__credit-card-number {
            margin-bottom: 2rem;
        }

        .form__checkbox {
            line-height: 1.4;
            margin: 0 0 1.5rem 0;
        }

        .form__pw-wrapper {
            width: 100%;
            position: relative;
            input {
                //padding: calc(8rem/16) calc(12rem/16);
            }

            .form__pw-show-toggle {
                position: absolute;
                top: calc(15rem/16);
                right: calc(15rem/16);
                height: calc(30rem/16);
                width: calc(30rem/16);
                z-index: 2;
            }

            svg {
                height: 100%;
                width: 100%;
                z-index: 1;
                cursor: pointer;
                * {
                    fill: $lightGrey;
                }
            }
        }

        .form__popup-trigger {
            cursor: pointer;
            display: block;
            width: 100%;
            text-align: center;
            color: $sitePrimaryLightest;
            font-size: calc(14rem/16);
            margin: calc(8rem/16) 0 0 0;
        }

        h4 {
            width: 100%;
            text-align: center;
            position: relative;
            svg {
                height: calc(15rem/16);
                width: calc(15rem/16);
                position: relative;
                top: calc(2rem/16);
            }

            &:first-of-type {
                margin-top: 2rem;
            }

            &:before {
                content: '';
                position: absolute;
            }

            &:before {
                height: calc(3rem/16);
                width: 100%;
                background: $lighterGrey;
                top: calc(50% - (1.5rem/16));
                left: 0;
            }

            span {
                position: relative;
                z-index: 1;
                background: $white;
                padding: 0 calc(10rem/16);
            }
        }

        .form__input-text {
            margin: 0 0 1rem 0;
        }

        button {
            text-transform: uppercase;
            margin: 1rem 0 0 0;
            width: 100%;
            display: block;
            background: linear-gradient(90deg, #b760b4 0%, rgb(232,187,39) 100%);
            box-shadow: 0 calc(3rem/16) calc(2rem/16) rgba(0,0,0,0.2);
            text-shadow: calc(2rem/16) calc(2rem/16) calc(2rem/16) rgba(0, 0, 0, 0.3);
            font-size: calc(14rem/16);
            letter-spacing: calc(1rem/16);
            &:hover,
            &:focus,
            &:active {
                background: linear-gradient(90deg, #b760b4 0%, rgb(232,187,39) 100%);
            }
        }
    }

    @media screen and (min-width: $breakpointSmallMobile) {
        .form--sign-up {
            padding: 2rem;
            h1 {
                margin: 0rem 0 1.5rem 0;
            }
        }
    }

    @media screen and (min-width: $breakpointLargeMobile) {
        .form--sign-up {
            h1 {
                font-size: calc(32rem/16);
            }
        }
    }

    @media screen and (min-width: $breakpointSmallTablet) {
        .form--sign-up {
            flex-wrap: wrap;
            justify-content: space-between;
            .form__name-first,
            .form__name-last {
                width: calc(50% - (8rem/16));
            }
            h1 {
                margin: 0.5rem 0 1.5rem 0;
            }
        }
    }

    &.is-dark-mode {
        background: linear-gradient(180deg, rgba(14, 14, 14, 1) 0%, rgb(7, 7, 7) 100%);
        .form--sign-up {
            h4 {
                svg {
                    * {
                        fill: $white;
                    }
                }

                &:before {
                    background: $darkModeLight;
                }

                span {
                    background: $darkModeDark;
                }
            }
        }
    }
}
