.layout--carousel-landscape {
    position: relative;
    overflow: hidden;
    max-width: calc(1916rem/16);
    margin: 0 auto;
    padding: 1rem;
    .carousel-landscape__heading {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0 0 1rem 0;
        &.has-accent-color {
            padding: calc(12rem/16) 0 0 0;
        }

        .ickonic-button {
            text-transform: uppercase;
            margin: 0 0 0 1rem;
            background: $darkGrey;
            color: $lightGrey;
            padding: calc(4rem/16) calc(10rem/16);
            height: auto;
            line-height: 1;
            font-weight: 500;
            font-size: calc(12rem/16);
            &:hover,
            &:focus,
            &:active {
                background: $midGrey;
                color: $lighterGrey;
            }
        }
    }

    h2 {
        display: inline-block;
        margin: 0 0 0 0.5rem;
        position: relative;


        span {
            position: absolute;
            width: calc(60rem/16);
            height: calc(3rem/16);
            top: calc(-12rem/16);
            left: 0;
        }

        @media screen and (min-width: $breakpointSmallTablet) {
            margin: 0 0 0 3.5rem;
        }

        @media screen and (min-width: $breakpointDesktop) {
            margin: 0 0 0 4rem;
        }

        @media screen and (min-width: $breakpointSuperWide) {
            margin: 0 0 0 5rem;
        }
    }

    @media screen and (min-width: $breakpointSmallTablet) {
        padding: calc(8rem/16);
    }
}

.carousel-landscape__wrapper {
    position: relative;
}

.carousel-landscape__inner {
    position: relative;
    z-index: 1;
    width: 100%;
    @media screen and (min-width: $breakpointSmallTablet) {
        width: calc(100% - 6rem);
        margin: 0 3rem;
        overflow: hidden;
    }

    @media screen and (min-width: $breakpointSuperWide) {
        width: calc(100% - 8rem);
        margin: 0 4rem;
    }
}

.carousel-landscape__track {
    display: flex;
    transition: transform 0.45s $sexyEase;
    will-change: transform;
}

.carousel-landscape__item {
    flex: 0 0 auto;
    padding: 0.5rem; // calc(8rem / 16)
    position: relative; // Ensure label positioning works
    .carousel-landscape__thumbnail {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: transform 0.3s ease;
    }

    &:hover .carousel-landscape__thumbnail {
        transform: scale(1.05);
    }

    .carousel-landscape__item-label {
        z-index: 2;
        top: calc(5rem/16);
    }

    .carousel-landscape__item-title {
        line-height: 1.3;
        min-height: calc(16rem/16);
        margin: calc(10rem/16) 0 0 0;
        color: lighten($midGrey, 15%);
        display: block;
        text-align: center;
        position: relative;
        p {
            font-size: calc(12rem/16);
        }

        &:hover,
        &:focus,
        &:active {
            color: $lightestGrey;
        }
    }

    @media screen and (min-width: $breakpointSmallTablet) {
        padding: 0.5rem;
    }

    @media screen and (min-width: $breakpointDesktop) {
        padding: 1rem;
    }
}

.carousel-landscape__controls {
    display: none;

    @media screen and (min-width: $breakpointSmallTablet) {
        position: absolute;
        display: flex;
        top: calc(50% - (16rem/16));
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        justify-content: space-between;
    }

    .carousel-landscape__button {
        width: 3rem; // calc(48rem / 16) = 3rem
        height: 3rem;
        background: rgba(51, 51, 51, 0.5); // Replace $darkGrey with actual color
        border: none;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background 0.3s ease;

        &:hover {
            background: rgba(51, 51, 51, 0.8); // Replace $darkGrey with actual color
        }

        svg {
            width: 60%;
            height: 60%;
            fill: #fff;
        }
    }
}
