.popup--basic {
    padding: 1rem;
    top: -500%;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(10,10,10,0.95);
    display: flex;
    align-items: center;
    justify-content: center;
    &.has-transitions {
        transform: scale(1.1);
        opacity: 0;
        transition: transform .22s $sexyEase, top 0s linear .22s, opacity .22s $sexyEase;
    }

    .popup__inner {
        height: 100%;
        width: 100%;
        max-height: calc(900rem/16);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .popup__children {
        width: 100%;
        max-width: calc(600rem/16);
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (min-width: $breakpointSmallTablet) and (min-height: $breakpointDesktop) {
            transform: translateY(calc(-5vh));
        }
    }

    &.is-open {
        top: 0;
        &.has-transitions {
            opacity: 1;
            transform: scale(1);
            transition: transform .22s $sexyEase, top 0s linear, opacity .22s $sexyEase;
        }
    }

    .popup__close {
        position: absolute;
        top: calc(10rem/16);
        right: calc(10rem/16);
        height: calc(30rem/16);
        width: calc(30rem/16);
        margin: 0 0 1rem 0;
        cursor: pointer;
        border-radius: calc(15rem/16);
        transition: background .25s $sexyEase, box-shadow .25s $sexyEase;
        svg {
            * {
                fill: white;
            }
        }

        &:hover,
        &:focus {
            background: #333;
            box-shadow: 0 0 0 calc(7rem/16) #333;
        }
    }
}
