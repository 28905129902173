.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip {
    position: absolute;
    bottom: calc(100% + (20rem/16));
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    background-color: lighten($darkModeLight, 7%);
    color: white;
    width: calc(250rem/16);
    padding: calc(12rem/16);
    border-radius: calc(4rem/16);
    font-size: calc(14rem/16);
    text-align: left;
    box-shadow: 0 0 calc(5rem/16) calc(2rem/16) rgba(0,0,0,0.3);
    span {
        line-height: 1.7;
        padding: 0 !important;
        background: none !important;
    }

    &:after {
        content: '';
        position: absolute;
        width: calc(16rem/16);
        height: calc(16rem/16);
        transform: rotate(45deg);
        left: calc(50% - (8rem/16));
        bottom: calc(-8rem/16);
        background-color: lighten($darkModeLight, 7%);
    }

    @media screen and (min-width: $breakpointSmallTablet) {
        width: calc(300rem / 16);
    }
}

.is-sixteen-nine-aspect-ratio {
    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 0;
    padding-top: 56.25%; // 16:9 aspect ratio (9/16 = 0.5625)
    background-color: #202020; // Fallback color if image fails to load
    background-size: cover;
    background-position: center;
    overflow: hidden;
    text-decoration: none;
}

.free-view-content-item-label {
    text-transform: uppercase;
    color: $siteSecondary;
    box-shadow: 0 0 0 calc(1rem/16) $siteSecondaryDarker;
    background: $siteSecondaryDarkest;
    padding: calc(3rem/16);
    border-radius: calc(6rem/16);
    position: absolute;
    top: 0;
    left: calc(50% - calc(55rem/16));
    width: calc(110rem/16);
    font-size: calc(12rem/16);
    text-align: center;
}

.early-access-content-item-label {
    text-transform: uppercase;
    font-weight: bold;
    background: $black;
    box-shadow: 0 0 0 calc(1rem/16) rgba(232,187,39,0.5);
    padding: calc(3rem/16);
    border-radius: calc(6rem/16);
    position: absolute;
    top: 0;
    left: calc(50% - calc(55rem/16));
    width: calc(110rem/16);
    font-size: calc(12rem/16);
    text-align: center;
    span {
        background: linear-gradient(90deg, $siteAccent 0%, $sitePrimaryLightest 100%);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
    }
}
