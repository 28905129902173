.video-controls__button {
    &.video-controls--theatre-mode {
        display: none;
        svg {
            padding: calc(7rem/16);
        }

        @media screen and (min-width: $breakpointSmallTablet) {
            display: inherit;
        }
    }
}
