.cancel-or-pause__step-select-reason {
    .step-select-reason__member-since {
        display: table;
        padding: 0 calc(16rem/16);
        margin: calc(16rem/16) auto 0 auto;
        & > span {
            font-size: calc(14rem/16);
            background: $blueToPurpleGradient;
            padding: calc(4rem/16) calc(10rem/16);
            font-weight: 500;
            font-family: $tertiaryFont;
            display: inline-block;
            color: $white;
            text-shadow: calc(1rem/16) calc(1rem/16) calc(1rem/16) rgba(0, 0, 0, 0.8);
            .step-select-reason__member-since-date {
                position: relative;
                z-index: 1;
            }

            &.has-loaded {
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    height: calc(23rem/16);
                    width: calc(23rem/16);
                    transform: rotate(45deg);
                    top: calc(6rem/16);
                }

                &:before {
                    background: $globalCurrentEvents;
                    left: calc(4rem/16);
                }

                &:after {
                    background: $sitePrimaryLightest;
                    right: calc(4rem/16);
                }
            }
        }
    }
}
