.button--prev-next {
    .prev-next__button {
        border: none;
        background: none;
        box-shadow: none;
        width: calc(50rem/16);
        height: calc(50rem/16);
        padding: calc(15rem/16);
        cursor: pointer;
        opacity: 0.7;
        transition: opacity .25s $sexyEase;
        &:first-of-type {
            svg {
                transform: rotate(180deg);
            }
        }

        &:hover,
        &:active,
        &:focus {
            opacity: 1;
            outline: none;
            box-shadow: none;
        }

        &:disabled {
            opacity: 0.1;
            cursor: default;
        }

        svg {
            width: 100%;
            height: 100%;
            * {
                fill: $white;
            }
        }
    }
}
