.layout--david-icke-with-content-two-column {
    padding: 1rem 0 2rem 0;
    background: #03031c;
    .layout__gradient-transition-top,
    .layout__gradient-transition-bottom {
        position: absolute;
        left: 0;
        top: 0;
        height: calc(100rem/16);
        width: 100%;
        background: #03031c;
        background: linear-gradient(180deg, #03031c 0%, rgba(32,32,32,0) 100%);
        z-index: 1;
    }

    .layout__gradient-transition-bottom {
        top: unset;
        bottom: 0;
        background: #03031c;
        background: linear-gradient(0deg, #03031c 0%, rgba(32,32,32,0) 100%);
    }

    .column__stars {
        position: absolute;
        top: -50%;
        left: 15%;
        width: 100%;
        height: 100%;
        opacity: 0.1;
        transform: rotate(280deg);
    }

    .layout__column {
        margin: 0 auto;
    }

    .layout__column-one {
        position: relative;
        max-width: calc(350rem/16);
        .column__gradient-overlay {
            position: absolute;
            left: 0;
            bottom: 0;
            height: calc(100rem/16);
            width: 100%;
            background: #03031c;
            z-index: 2;
            background: linear-gradient(0deg, #03031c 0%, rgba(32,32,32,0) 100%);
        }

        .column__portrait {
            position: relative;
            z-index: 1;
        }
    }

    .layout__column-two {
        padding: 1rem;
        max-width: calc(440rem/16);
        position: relative;
        z-index: 2;
        h1 {
            font-family: $tertiaryFont;
            text-transform: uppercase;
            font-weight: bold;
            span {
                display: block;
                background: linear-gradient(180deg, #4676E6 0%, #b760b4 100%);
                background-clip: text;
                -webkit-background-clip: text;
                color: transparent;
            }

            @media screen and (min-width: $breakpointLargeMobile) {
                font-size: calc(35rem/16);
            }

            @media screen and (min-width: $breakpointDesktop) {
                font-size: calc(40rem/16);
            }

            @media screen and (min-width: $breakpointWide) {
                font-size: calc(45rem/16);
            }
        }

        p {
            max-width: calc(600rem/16);
            margin: 1rem 0 1.5rem 0;
        }

        .ickonic-button {
            margin: 0;
            text-shadow: calc(2rem/16) calc(2rem/16) calc(2rem/16) rgba(0, 0, 0, 0.3);
            svg {
                width: calc(12rem/16);
                height: calc(12rem/16);
                margin: 0 0 0 calc(10rem/16);
            }
        }
    }

    @media screen and (min-width: $breakpointSmallTablet) {
        .layout__column-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: calc(1500rem/16);
            margin: 0 auto;
        }

        .layout__column {
            margin: 0;
        }

        .column__stars {
            top: -40%;
        }

        .layout__column-one {
            width: 50%;
            max-width: none;
        }

        .layout__column-two {
            width: 50%;
            max-width: none;
        }
    }

    @media screen and (min-width: $breakpointDesktop) {
        .layout__column-wrapper {
            transform: translateY(-3rem);
            position: relative;
            z-index: 2;
        }
    }

    @media screen and (min-width: $breakpointWide) {
        .layout__column-wrapper {
            transform: translateY(-4rem);
        }

        .layout__column-one {
            width: 55%;
        }

        .layout__column-two {
            width: 45%;
            padding: 1rem 3rem 1rem 1rem;
        }
    }
}
