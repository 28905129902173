.carousel--portrait {
    position: relative;
    overflow: hidden;
    max-width: calc(1916rem/16);
    margin: 0 auto;
    padding: 1rem;
    .carousel-portrait__heading {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0 0 1rem 0;
        .ickonic-button {
            margin: 0 0 0 1rem;
            text-transform: uppercase;
            background: $darkGrey;
            color: $lightGrey;
            padding: calc(4rem/16) calc(10rem/16);
            height: auto;
            line-height: 1;
            font-weight: 500;
            font-size: calc(12rem/16);
            &:hover,
            &:focus,
            &:active {
                background: $midGrey;
                color: $lighterGrey;
            }
        }
    }

    h2 {
        padding: 0 0 0 calc(8rem/16);
        margin: 0;
        @media screen and (min-width: $breakpointSmallTablet) {
            padding: 0 0 0 3.5rem;
        }

        @media screen and (min-width: $breakpointDesktop) {
            padding: 0 0 0 4rem;
        }

        @media screen and (min-width: $breakpointSuperWide) {
            padding: 0 0 0 5rem;
        }
    }

    @media screen and (min-width: $breakpointSmallTablet) {
        padding: calc(8rem/16);
    }
}

.carousel-portrait__inner {
    position: relative;
    z-index: 1;
    @media screen and (min-width: $breakpointSmallTablet) {
        width: calc(100% - 6rem);
        margin: 0 3rem;
        overflow: hidden;
    }

    @media screen and (min-width: $breakpointSuperWide) {
        width: calc(100% - 8rem);
        margin: 0 4rem;
    }
}

.carousel-portrait__track {
    display: flex;
    transition: transform .45s $sexyEase;
    will-change: transform;
}

.carousel-portrait__item {
    flex: 0 0 auto;
    width: 100%;
    padding: calc(8rem / 16);
    & > a {
        display: block;
        background: #202020;
        position: relative;
        width: 100%;
        padding-top: 133.33%;
    }

    label {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        span {
            text-align: center;
            padding: calc(8rem / 16) calc(12rem / 16) calc(7rem / 16) calc(12rem / 16);
            color: $siteSecondary;
            box-shadow: 0 0 0 calc(1rem/16) $siteSecondaryDarker;
            background: $siteSecondaryDarkest;
            line-height: 1;
            font-size: calc(12rem/16);
            display: block;
            text-transform: uppercase;
            font-family: $secondaryFont;
            border-top-left-radius: calc(8rem / 16);
            border-top-right-radius: calc(8rem / 16);
        }
    }

    @media screen and (min-width: $breakpointSmallMobile) {
        & > a {
            height: 56vw;
        }

        label {
            span {
                font-size: calc(10rem/16);
            }
        }
    }

    @media screen and (min-width: $breakpointLargeMobile) {
        label {
            span {
                font-size: calc(12rem/16);
            }
        }
    }

    @media screen and (min-width: $breakpointSmallTablet) {
        & > a {
            height: 50vw;
        }
    }

    @media screen and (min-width: $breakpointTablet) {
        & > a {
            height: 35vw;
        }

        label {
            span {
                font-size: calc(14rem/16);
            }
        }
    }

    @media screen and (min-width: $breakpointDesktop) {
        padding: 1rem;
        & > a {
            height: 25vw;
        }
    }

    @media screen and (min-width: $breakpointWide) {
        & > a {
            height: 17vw;
            max-height: calc(329rem/16);
        }
    }
}

.carousel-portrait__controls {
    display: none;

    @media screen and (min-width: $breakpointSmallTablet) {
        position: absolute;
        display: flex;
        top: calc(50% + calc(28rem/16));
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        justify-content: space-between;
    }

    @media screen and (min-width: $breakpointSmallTablet) {
        top: calc(50% + calc(32rem/16));
        .carousel-portrait__button {
            width: calc(48rem/16);
            height: calc(48rem/16);
        }
    }
}
