.ickonic--cookie-consent {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: $white;
    z-index: 1000;
    color: $black;
    box-shadow: 0 0 calc(3rem/16) rgba(0,0,0,0.2);
    &.is-dark-mode {
        background: $darkModeLight;
        color: $white;
        button {
            &:hover,
            &:focus,
            &:active {
                color: $white;
            }
        }
    }

    @media screen and (min-width: calc(186em/16)) {
        height: calc(70rem/16);
    }

    @media screen and (min-width: calc(360em/16)) {
        height: calc(55rem/16);
    }

    &.is-hidden {
        top: -100%;
        opacity: 0;

    }

    .cookie-consent__inner {
        display: flex !important;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        padding: 0;
        min-height: $headerHeight;
        @media screen and (min-width: calc(700em/16)) {
            flex-direction: row;
            justify-content: center;
        }

        > div {
            margin: 0 auto !important;
            flex: unset !important;
            &:first-child {
                margin: 0 !important;
                padding: 0.75rem !important;
                text-align: center;
            }

            &:last-child {
                width: calc(165rem/16) !important;
            }
        }

        span {
            font-size: calc(12rem/16);
            text-align: center;
            @media screen and (min-width: calc(700em/16)) {
                font-size: calc(16rem/16);
            }
        }
    }

    button {
        width: auto;
        color: white;
        box-shadow: 0 0 0 calc(2rem/16) $sitePrimary;
        background: $sitePrimary;
        transition: background .25s $sexyEase, color .25s $sexyEase;
        font-weight: bold;
        font-size: calc(12rem/16);
        border-radius: calc(15rem/16);
        border: none;
        cursor: pointer;
        display: inline-block;
        padding: 0 calc(20rem/16);
        margin: 0 auto;
        &:hover,
        &:focus,
        &:active {
            box-shadow: 0 0 0 calc(2rem/16) $sitePrimary;
            background: none;
            color: $sitePrimary;
        }

        @media screen and (min-width: calc(700em/16)) {
            margin: 0 0 0 calc(20rem/16);
            font-size: calc(16rem/16);
        }
    }

    @media screen and (min-width: $breakpointTablet) {
        .cookie-consent__inner {
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            padding: 0;
            > div {
                &:first-child {
                    margin: 0;
                }

                &:last-child {
                    margin: 0 0 0 calc(8rem/16);
                }
            }
        }
    }
}
