.layout--hero-one-column {
    background: $black;

    // Over-rides dark / light mode defaults
    * {
        color: $white;
    }

    .layout__inner {
        padding: 1rem;
        margin: 0 auto;
    }

    h1, h2, h3, h4, h5, h6, p {
        &:first-child {
            margin-top: 0;
        }
    }
}
