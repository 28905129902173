.ickonic-button {
    padding: calc(10rem / 16) calc(25rem / 16);
    margin: 0;
    background: $darkGrey;
    color: $white;
    border: none;
    display: table;
    box-shadow: none;
    cursor: pointer;
    font-family: $tertiaryFont;
    font-weight: 600;
    transition: background .25s $sexyEase, color .25s $sexyEase, box-shadow .25s $sexyEase;
    &:hover,
    &:focus {
        background: $midGrey;
    }
}
