.video-controls__volume {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(120rem/16);
    height: calc(36rem/16);
    margin: 0 calc(22rem/16) 0 0;
    &:hover,
    &:focus,
    &:active {
        .volume__track {
            opacity: 1;
        }
    }

    button {
        padding: 0;
        margin: 0;
        background: none;
        box-shadow: none;
        outline: none;
        border: 0;
        height: calc(36rem/16);
        width: calc(36rem/16);
        svg {
            padding: calc(9rem/16);
        }
    }

    .volume__track {
        width: calc(100% - (39rem/16));
        height: calc(10rem/16);
        position: relative;
        cursor: pointer;
        opacity: 0;
        transition: opacity .25s $sexyEase;
        &:after {
            content: '';
            position: absolute;
            top: calc(50% - (2rem/16));
            left: 0;
            width: 100%;
            height: calc(4rem/16);
            background: $midGrey;
            border-radius: calc(2rem/16);
        }
    }

    .volume__runner {
        position: absolute;
        top: calc(50% - (2rem/16));
        left: 0;
        height: calc(4rem/16);
        background: $sitePrimary; // Change to your primary color
        border-radius: calc(2rem/16);
        z-index: 1;
    }

    .volume__thumb {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: calc(10rem/16);
        height: calc(10rem/16);
        border-radius: 50%;
        background: $sitePrimary;
        cursor: pointer;
        z-index: 2;
    }
}
