.content--portrait-image-item {
    label {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        span {
            text-align: center;
            padding: calc(8rem / 16) calc(12rem / 16) calc(7rem / 16) calc(12rem / 16);
            color: $siteSecondary;
            box-shadow: 0 0 0 calc(1rem/16) $siteSecondaryDarker;
            background: $siteSecondaryDarkest;
            line-height: 1;
            font-size: calc(12rem/16);
            display: block;
            text-transform: uppercase;
            font-family: $secondaryFont;
            border-top-left-radius: calc(8rem / 16);
            border-top-right-radius: calc(8rem / 16);
        }
    }
}
