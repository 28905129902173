// Links
// ==========================================================================

// Remove the gray background color from active links in IE 10.
a {
    background-color: transparent;
    text-decoration: none;
    color: $linkColor;
    cursor: pointer;
    &:visited {
        color: $linkColor;
    }

    &:active {
        outline: 0;
        color: $linkHover;
    }

    &:hover,
    &:focus,
    &:active {
        outline: 0;
        color: $linkHover;
    }

    &.read-more-dots {
        text-decoration: none;
        color: $sitePrimary;
        &:hover {
            color: $linkHover;
        }
    }
}
