.video-player__progress-bar {
    height: calc(16rem/16);
    padding: calc(6rem/16) 0 0 0;
    margin: 0 1.5rem;
    cursor: pointer;
    width: calc(100% - (46rem/16));
    .progress-bar__inner {
        position: relative;
        height: calc(4rem/16);
        border-radius: calc(2rem/16);
        background: rgba(255,255,255,0.3);
    }

    .progress-bar__buffered {
        position: absolute;
        border-radius: calc(2rem/16);
        left: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(255,255,255,0.5);
    }

    .progress-bar__progress {
        height: 100%;
        border-radius: calc(2rem/16);
        background: $blueToPurpleGradient;
        position: relative;
    }

    .progress-bar__thumb {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(50%, -50%);
        width: calc(24rem/16);
        height: calc(24rem/16);

        // pointerEvents: 'auto' so we can grab it
        pointer-events: auto;
        cursor: grab;
        .progress-bar__thumb-nail {
            position: absolute;
            top: calc(6rem/16);
            left: calc(6rem/16);
            width: calc(12rem/16);
            height: calc(12rem/16);
            background: $sitePrimaryLightest;
            border-radius: 50%;
        }
    }
}
