.account-dropdown {
    border: 0;
    outline: 0;
    box-shadow: none;
    background: none;
    padding: 0;
    &:hover,
    &:focus,
    &:active {
        border: 0;
        outline: 0;
        box-shadow: none !important;
        background: none !important;
    }

    .account-dropdown__button {
        border: 0;
        background: none;
        position: relative;
        top: 0;
        right: 0;
        height: calc($headerHeight - (18rem/16));
        width: auto;
        cursor: pointer;
        border-radius: calc(16rem/16);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: calc(9rem/16);
        padding: 0 calc(5rem/16);
        z-index: 2;
        box-shadow: 0 0 0 calc(1rem/16) $darkGrey;
        color: $baseFontColor;
        transition: background .15s ease-in-out, box-shadow .15s ease-in-out;
        &:hover,
        &:focus,
        &:active {
            box-shadow: 0 0 0 calc(2rem/16) $white !important;
            background: $white;
            .header__profile-icon {
                svg {
                    * {
                        fill: $white;
                    }
                }
            }

            & > span {
                color: $black;
            }
        }

        & > span {
            display: none;
        }
    }

    &:visited {
        color: $baseFontColor;
    }

    .account-dropdown__icon {
        background: $darkGrey;
        height: calc(22rem/16);
        width: calc(22rem/16);
        border-radius: 50%;
        position: relative;
        span {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            line-height: calc(22rem/16);
            background: $sitePrimary;
            border-radius: 50%;
            font-size: calc(18rem/16);
        }

        svg {
            height: 100%;
            width: 100%;
            display: inline-block;
            padding: calc(1rem/16);
            * {
                fill: $white;
                transition: fill .15s ease-in-out;
            }
        }
    }

    .account-dropdown__dropdown {
        position: absolute;
        top: -500%;
        opacity: 0;
        background: $darkerGrey;
        right: 0;
        width: calc(250rem/16);
        box-shadow: inset 0 calc(3rem/16) calc(3rem/16) 0 rgba(0,0,0,0.2);
        transform: translateY(calc(20rem/16));
        border-bottom-left-radius: calc(6rem/16);
        transition: top 0s linear .25s, opacity .25s $sexyEase, transform .25s $sexyEase;
        ul, li {
            list-style: none;
        }

        ul {
            margin: 0;
            padding: calc(20rem/16);
        }

        li {
            a, button {
                color: $baseFontColor;
                text-align: left;
                padding: calc(10rem/16);
                background: none;
                width: 100%;
                border: 0;
                outline: none;
                cursor: pointer;
                font-size: calc(14rem/16);
                font-family: $tertiaryFont;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border-radius: calc(4rem/16);
                transition: background .25s $sexyEase;
                svg {
                    width: calc(16rem/16);
                    height: calc(16rem/16);
                    margin: 0 calc(10rem/16) 0 0;
                    * {
                        fill: $white;
                    }
                }
            }

            a {
                margin: 0 0 calc(10rem/16) 0;
                &:hover,
                &:focus,
                &:active {
                    background: $darkGrey;
                }
            }

            button {
                box-shadow: 0 0 0 calc(1rem/16) $siteError;
                &:hover,
                &:focus,
                &:active {
                    background: $siteError;
                }
            }
        }

        &.is-open {
            top: calc(41rem/16);
            opacity: 1;
            transform: translateY(0);
            transition: top 0s linear 0s, opacity .25s $sexyEase, transform .25s $sexyEase;
        }
    }

    @media screen and (min-width: $breakpointSmallTablet) {
        .account-dropdown__button {
            padding: 0 calc(8rem/16) 0 calc(14rem/16);
            & > span {
                display: inherit;
                font-size: calc(14rem / 16);
                font-family: $baseFont;
                transition: color .15s ease-in-out;
            }
        }

        .account-dropdown__icon {
            margin: 0 0 0 calc(6rem/16);
            transform: translateX(calc(2rem/16));
        }
    }
}
