// ---- ---- ---- ---- VARIABLES ---- ---- ---- ----

// Breakpoints
$breakpointSmallMobile: 20em; 	    // 320px
$breakpointLargeMobile: 25em; 	    // 400px
$breakpointSmallTablet: 36.5em; 	// 584px
$breakpointTablet: 47.5em;			// 760px
$breakpointDesktop: 63em;			// 1008px
$breakpointWide: 79.5em;		    // 1272px
$breakpointSuperWide: 115em;		// 1840px
$breakpointFinal: 133.25em;         // 1920px

// Header
$headerHeight: calc(50rem/16);
$notifyBarHeight: calc(28rem/16);

// "Brand" Colors

//$sitePrimaryLight: #df4bdc;
//$sitePrimaryLightest: #b760b4;
//$sitePrimaryDark: #892686;

$sitePrimary: #A84896;
$sitePrimaryLight: #df4bdc;
$sitePrimaryLightest: #b760b4;
$sitePrimaryDark: #892686;
$sitePrimaryDarker: #631960;
$sitePrimaryDarkestDesaturated: #2f252e;

$siteSecondary: #D9B2FF;
$siteSecondaryDarker: #423370;
$siteSecondaryDarkest: #0f0f1c;

//$siteSecondary: #628B48;
$siteAccent: #e8bb27;
$siteAccentDark: #e5ae2c;

// Category Colors
$ickonicOriginals: $sitePrimary;
$healthAndWellbeing: #098577;
$consciousness: #EBB13E;
$globalCurrentEvents: #4676E6; // Used for David Icke category as well
$documentaries: #8240A5;
$hiddenKnowledge: #901B1B;

// Utility Colors
$siteError: #da2626;
$siteWarning: #d1a80a;
$siteOkay: #1F9D01;

// Common Gradients
$goldToPinkGradient: linear-gradient(90deg, rgb(232,187,39) 0%, rgba(187, 54, 184, 1) 100%);
$pinkToGoldGradient: linear-gradient(90deg, rgba(187, 54, 184, 1) 0%, rgb(232,187,39) 100%);
$blueToPurpleGradient: linear-gradient(90deg, $globalCurrentEvents 0%, $sitePrimaryLightest 100%);
$pinkToPurpleGradient: linear-gradient(225deg, rgba(187, 54, 184, 1) 0%, $sitePrimaryLightest 100%);
$purpleToBlueGradient: linear-gradient(225deg, $sitePrimaryLightest 0%, $globalCurrentEvents 100%);

// Greys
$siteMidGrey: #303036;
$black: #000;
$darkestGrey: #151515;
$darkerGrey: #202020;
$darkGrey: #404040;
$midGrey: #808080;
$lightGrey: #bfbfbf;
$lighterGrey: #e6e6e6;
$lightestGrey: #f0f0f0;
$white: #fff;

// Dark Mode
$darkModeDark: #0e0e0e;
$darkModeMid: #121212;
$darkModeLight: #202020;
$darkModeLighter: #303030;
$darkModeHighlight: #e3e3e3;

// Root
$bodyBGColor: #0f0f0f;
$bodyFontSize: 100%;
$columnMargin: .5em;
$gutter: 1em;
$warningColor: $siteError;

// Typography
$baseFont: "WorkSans", Helvetica, sans-serif;
$baseFontColor: $white;
$baseFontSize: 100%;
$baseFontWeight: 400;
$baseLineHeight: 1.75;
$codeFont: $baseFont;

// Secondary Font
// replace w/ $baseFont when only
// using a single font.
$secondaryFont: "Rubik", Helvetica, sans-serif;
$tertiaryFont: "Outfit", Helvetica, sans-serif;

// Links
$linkColor: $sitePrimaryLight;
$linkHover: darken($linkColor, 5%);
$linkVisited: darken($linkColor, 10%);
$linkActive: darken($linkColor, 15%);
$linkWeight: bold;

// Labels
$labelFontSize: calc(14rem/16);
$labelColor: $black;

// Tables
$tableCellPadding: .25rem;
$tableStripe: $lighterGrey;
$tableFontSize: calc(14rem/16);

// Blockquotes
$blockquoteMarginBottom: calc(14rem/16);
$blockquotePaddingLeft: 0;
$blockquoteBorderWidth: 0;
$blockquoteBorderStyle: solid;
$blockquoteBorderColor: $lighterGrey;
$blockquoteColor: $midGrey;
$blockquoteFontFamily: $baseFont;
$blockquoteFontSize: 1.25rem;
$blockquoteFontStyle: italic;
$blockquoteFontWeight: 200;

// Code/Pre blocks
$codeFontFamily: $codeFont;
$codeFontSize: calc(14rem/16);
$codeColor: $baseFontColor;

// Transition Easing
$sexyEase: cubic-bezier(.84,0,.13,.98);
$snappyEase: cubic-bezier(1.000, 0.005, 0.290, 1.000);

// Transition synced timing
$darkModeTransitionTimer: .35s;
