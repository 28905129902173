.watch-video__email-free-view {
    .email-free-view__error-message {
        font-size: calc(14rem/16);
        color: $siteError;
        font-weight: 500;
        margin: 0 auto !important;
    }

    .ickonic-button {
        min-height: calc(38rem/16);
        padding: calc(12rem/16);
        height: calc(25rem/16);
        position: relative;
        margin: 0 auto 1rem auto;
        width: 100%;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: width .45s $sexyEase;
        animation: none !important;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: calc(4rem/16);
        border-bottom-left-radius: calc(4rem/16);
        background: $darkGrey;
        color: $midGrey;
        svg {
            transform: translateY(calc(1rem/16));
            * {
                fill: $black;
            }
        }

        &.is-valid {
            background: $siteAccent;
            color: $black;
        }

        &.has-success {
            background: $sitePrimaryLightest;
            .button__icon {
                border-radius: 50%;
                animation-name: pulseHuge;
                animation-duration: 700ms;
                animation-timing-function: $sexyEase;
            }
        }

        .button__icon {
            width: calc(25rem/16);
            height: calc(25rem/16);
            margin: 0 auto;
            position: absolute;
            top: calc(50% - (13rem/16));
            left: calc(50% - (13rem/16));
            * {
                fill: black;
            }

            &.icon--loading {
                animation-name: spin;
                animation-duration: 1200ms;
                animation-iteration-count: infinite;
                animation-timing-function: $sexyEase;
                * {
                    fill: white;
                }
            }
        }
    }

    .input-wrapper__cover {
        border-top-left-radius: calc(4rem/16);
        border-top-right-radius: calc(4rem/16);
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        input[type=email] {
            border-top-left-radius: calc(4rem/16);
            border-top-right-radius: calc(4rem/16);
            border-bottom-right-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }
    }

    p {
        font-size: calc(9rem/16);
        color: #ccc;
        text-align: center;
        margin: calc(8rem/16) auto 0 auto;
        a {
            font-size: calc(9rem/16);
        }

        @media screen and (min-width: $breakpointSmallTablet) {
            max-width: calc(350rem/16);
        }
    }
}
