.watch-video__primary-info {
    margin: 0 auto;

    h1,
    .primary-info__date-and-series {
        position: relative;
        min-height: calc(24rem/16);
        //min-width: calc(250rem/16);
        &.loader--side-to-side {
            border-radius: calc(8rem/16);
        }
    }

    h1 {
        margin: 0 0 1.25rem 0;
        font-size: calc(20rem/16);
        font-weight: 600;

    }

    .primary-info__top-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: stretch;

    }

    .primary-info__title-and-date {
        @media screen and (min-width: calc(1440em/16)) {
            width: calc(50% - (8rem/16));
        }

        @media screen and (min-width: calc(1750em/16)) {
            width: calc(60% - (8rem/16));
        }
    }

    .primary-info__date-and-series {
        font-size: calC(12rem/16);
        margin: 0 0 calc(4rem/16) 0;
        color: $midGrey;
        display: table;
        width: auto;
    }

    .primary-info__auxillary-info {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        @media screen and (min-width: calc(1440em/16)) {
            width: calc(50% - (8rem/16));
            justify-content: flex-end;
        }

        @media screen and (min-width: calc(1750em/16)) {
            width: calc(40% - (8rem/16));
        }
    }

    .primary-info__rating-and-share {
        overflow-y: auto;
        margin: 0 0 1rem 0;
        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .primary-info__description-container {
        padding: 1rem;
        background: $darkerGrey;
        border-radius: calc(8rem/16);
        min-height: calc(82rem/16);
        &.has-no-description {
            display: none;
        }

        &.has-short-description {
            &:after {
                content: none;
            }
        }

        .primary-info__gradient-shield {
            position: absolute;
            bottom: 0;
            left: 0;
            background: $darkerGrey;
            width: 100%;
            height: calc(32rem/16);
            &:after {
                content: '';
                position: absolute;
                top: calc(-40rem/16);
                left: 0;
                background: linear-gradient(0deg, $darkerGrey 0%, rgba(32,32,32,0) 100%);
                width: 100%;
                height: calc(40rem/16);
            }
        }

        .ickonic-button {
            line-height: 1;
            position: absolute;
            background: darken($midGrey, 10%);
            font-weight: normal;
            font-size: calc(12rem/15);
            text-transform: none;
            bottom: calc(16rem/16);
            left: calc(16rem/16);
            padding: calc(5rem/16) calc(8rem/16) calc(6rem/16) calc(8rem/16);
            border-radius: calc(4rem/16);
            z-index: 1;
            &:hover,
            &:focus,
            &:active {
                background: $midGrey;
                box-shadow: none;
                outline: none;
            }
        }
    }

    .primary-info__description {
        font-size: calc(14rem/16);
        &.collapsed {
            max-height: 30px; /* Adjust as needed */
            overflow: hidden;
            position: relative;
        }

        &.expanded {
            max-height: none;
        }
    }
}
