.video-player__play-pause {
    opacity: 0;
    transition: opacity 1s $sexyEase;
    &.video-controls__button {
        display: none;
        margin: 0;
        .play-pause__inner {
            svg {
                padding: calc(8rem/16);
                &.ickonic-logo--icon {
                    padding: calc(6rem/16);
                }
            }
        }

        @media screen and (min-width: $breakpointSmallTablet) {
            display: inherit;
        }
    }

    &.is-not-hidden {
        transition: opacity .25s $sexyEase;
        opacity: 1;
    }

    .play-pause__inner {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 10;
        svg {
            & > g {
                fill: $white;
                & > circle {
                    opacity: 0;
                }
            }

            & > circle {
                opacity: 0;
            }
        }
    }
}
