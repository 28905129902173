.layout--accordion {
    border-radius: calc(10rem/16);
    background: rgba(255,255,255,0.2);
    backdrop-filter: blur(10px); /* Apply blur to the background behind this box */
    -webkit-backdrop-filter: blur(10px); /* Safari support */
    padding: 1rem 1.5rem;
    margin: 0 0 calc(3rem/16) 0;
    .accordion__heading {
        display: flex;
        justify-content: space-between;
        h4 {
            color: $lighterGrey;
            font-weight: 400;
            font-size: calc(18rem/16);
            margin: 0;
            cursor: pointer;
            font-family: $tertiaryFont;
            padding: 1rem 0;
            width: calc(100% - (24rem/16));
            transition: color .45s $sexyEase;
        }

        svg {
            cursor: pointer;
            margin: calc(16rem/16) 0 0 0;
            width: calc(24rem/16);
            height: calc(24rem/16);
            transition: transform .65s $sexyEase;
            * {
                fill: $lighterGrey
            }
        }
    }

    .accordion__content {
        transition: height .45s $sexyEase, opacity .45s $sexyEase;
        padding: 0 calc(2rem/16);
        color: $lighterGrey;
        opacity: 0;
    }

    &.is-active {
        svg {
            transform: rotate(315deg);
        }

        .accordion__content {
            opacity: 1;
        }
    }

    @media screen and (min-width: $breakpointLargeMobile) {
        .accordion__heading {
            h4 {
                font-size: calc(22rem / 16);
            }
        }
    }
}
