.page--tv-app-auth {
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 3rem 1rem;
    .tv-apps-auth__logo {
        width: calc(130rem/16);
        svg {
            width: 100%;
            height: 100%;
            & > circle {
                transition: stroke $darkModeTransitionTimer $sexyEase;
                stroke: $white;
            }
        }

        g {
            * {
                transition: fill $darkModeTransitionTimer $sexyEase;
                fill: $white;
            }
        }
    }

    .tv-apps-auth__inner {
        width: 100%;
        border-radius: calc(8rem/16);
        text-align: center;
        background: $darkerGrey;
        position: relative;
        color: $white;
        max-width: calc(700rem/16);
        svg {
            font-size: calc(60rem/16);
        }

        h2 {
            margin: 0;
            padding: 2rem 1rem;
            font-family: $tertiaryFont;
        }
    }

    .tv-apps-auth__content {
        padding: 1rem 2rem;
        input[type=text] {
            margin-top: 1rem;
            background: darken($darkerGrey, 4%);
        }
    }

    .tv-apps-auth__button-wrapper {
        margin: 2rem auto 1rem auto;
        max-width: calc(400rem/16);
        p {
            margin: calc(5rem/16) auto;
            font-size: calc(12rem/16);
            width: calc(40rem/16);
            text-transform: uppercase;
        }

        .ickonic-button {
            width: 100%;
            font-weight: normal;
            margin: 0;
            &:first-of-type {
                background: $blueToPurpleGradient;
                color: $white;
                text-shadow: calc(1rem/16) calc(1rem/16) calc(1rem/16) rgba(0, 0, 0, 0.8);
            }

            &:last-of-type {
                background: none;
                box-shadow: 0 0 0 calc(2rem/16) rgba(183,96,180,0.5);
                &:hover,
                &:focus,
                &:active {
                    background: $sitePrimary;
                    box-shadow: 0 0 0 calc(2rem/16) $sitePrimary;
                }
            }

            &.button-wrapper__submit {
                cursor: default;
                background: none;
                box-shadow: 0 0 0 calc(2rem/16) $darkGrey;
                &:hover,
                &:focus,
                &:active {
                    background: none;
                    box-shadow: 0 0 0 calc(2rem/16) $darkGrey;
                }

                &.has-value {
                    cursor: pointer;
                    background: $sitePrimary;
                    box-shadow: 0 0 0 calc(2rem/16) $sitePrimary;
                    &:hover,
                    &:focus,
                    &:active {
                        background: none;
                    }
                }
            }
        }

        @media screen and (min-width: $breakpointSmallMobile) {
            display: flex;
            justify-content: center;
            align-items: center;
            p {
                margin: 0;
            }

            .ickonic-button {
                width: calc(50% - (20rem / 16));
            }
        }
    }

    .tv-apps-auth__unsubscribed {
        p {
            font-size: calc(14rem/16);
            margin: 0;
        }

        .tv-apps-auth__button-wrapper {
            margin-top: 1rem;
            .ickonic-button {
                background: $blueToPurpleGradient;
                color: $white;
                text-shadow: calc(1rem / 16) calc(1rem / 16) calc(1rem / 16) rgba(0, 0, 0, 0.8);
                box-shadow: none;
                &:hover,
                &:focus,
                &:active {
                    background: $blueToPurpleGradient;
                    color: $white;
                    text-shadow: calc(1rem / 16) calc(1rem / 16) calc(1rem / 16) rgba(0, 0, 0, 0.8);
                    box-shadow: none;
                }
            }
        }
    }

    .tv-apps-auth__success {
        padding: 1rem 0;
        svg {
            display: block;
            margin: 0 auto 1rem auto;
            width: calc(50rem/16);
            height: calc(50rem/16);
            padding: calc(2rem/16);
            animation: enlarge 0.75s $sexyEase;
            * {
                fill: rgba(183,96,180,1);
            }
        }
    }

    .tv-apps-auth__divider {
        height: calc(2rem/16);
        background: $blueToPurpleGradient;
    }
}
