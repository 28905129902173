.menu--header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $headerHeight;
    ul {
        margin: 0;
        padding: 0;
    }

    ul, li {
        list-style: none;
    }

    li {
        a {
            border-radius: calc(3rem/16);
            padding: calc(8rem/16) calc(7rem/16) calc(8rem/16) 0;
            transition: background .25s $sexyEase, padding .25s $sexyEase;
            color: $black;
            text-transform: uppercase;
            font-family: $secondaryFont;
            letter-spacing: calc(1rem/16);
            display: flex;
            align-items: center;
            p {
                margin: 0;
                font-size: calc(16rem/16);
                transform: translateY(calc(3rem/16));
            }
            .icon--decorative {
                margin: 0 calc(10rem/16) 0 0;
                transform: translateY(calc(2rem/16));
            }

            svg {
                width: calc(16rem/16);
                height: calc(16rem/16);
                margin: 0 0 0 calc(4rem/16);
                display: inline-block;
                * {
                    fill: $black;
                }
            }
        }

        ul {
            &.sub-menu {
                position: relative;
                display: block;
                &:before {
                    content: '';
                    position: absolute;
                    top: calc(12rem/16);
                    left: 0;
                    box-shadow: inset calc(2rem/16) 0 0 0 rgba(255,255,255,0.5);
                    width: calc(100% - calc(18rem/16));
                    height: calc(100% - calc(26rem/16));
                }

                li {
                    display: block;
                    a {
                        padding: calc(8rem/16) calc(8rem/16) calc(8rem/16) calc(20rem/16);
                        font-size: calc(12rem/16);
                        min-height: unset !important;
                        &:hover,
                        &:active,
                        &:focus {
                            padding: calc(8rem/16) calc(0rem/16) calc(8rem/16) calc(28rem/16) !important;
                        }
                    }
                }
            }
        }
    }

    @import 'slideout-sidebar';

    .menu__inner {
        padding: 1rem;
        height: calc(100vh - $headerHeight);
        &.menu-is-open {
            overflow-y: auto;
            li {
                a {
                    &:hover,
                    &:focus,
                    &:active {
                        background: rgba(0, 0, 0, 0.035);
                        padding: calc(8rem / 16) 0 calc(8rem / 16) calc(12rem / 16);
                    }
                }
            }
        }
    }

    .menu__horizontal {
        height: $headerHeight;
        position: relative;
        z-index: 2;
        margin: 0 0 0 calc(120rem/16);
        ul {
            display: flex;
            align-items: center;
            li {
                a {
                    border-radius: 0;
                    font-size: calc(14rem/16);
                    padding: calc(13rem/16) calc(12rem/16) calc(12rem/16) calc(12rem/16);
                    transition: box-shadow .1s ease-in-out, padding .1s ease-in-out;
                    &:hover,
                    &:focus,
                    &:active {
                        padding: calc(13rem/16) calc(12rem/16) calc(12rem/16) calc(12rem/16);
                        background: rgba(0, 0, 0, 0.035);
                    }
                }

                &:hover,
                &:active,
                &:focus {
                    ul {
                        &.sub-menu {
                            top: $headerHeight;
                            transform: translateY(calc(0rem/16));
                            opacity: 1;
                            transition: opacity .15s $sexyEase, transform .15s $sexyEase, top 0s linear 0s;
                        }
                    }
                }

                ul {
                    &.sub-menu {
                        position: absolute;
                        display: block;
                        min-width: calc(175rem/16);
                        top: -5000%;
                        transform: translateY(calc(10rem/16));
                        opacity: 0;
                        transition: opacity .15s $sexyEase, transform .15s $sexyEase, top 0s linear .25s;
                        &:before {
                            content: none;
                        }

                        li {
                            display: block;
                            background: darken($darkGrey, 7%);
                            a {
                                padding: calc(13rem/16) calc(12rem/16) calc(12rem/16) calc(12rem/16);
                                font-size: calc(14rem/16);
                                &:hover,
                                &:active,
                                &:focus {
                                    padding: calc(13rem/16) calc(12rem/16) calc(12rem/16) calc(12rem/16) !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.menu-is-active {
        .menu__slideout {
            transform: translateX(100%);
        }
    }

    &.is-dark-mode {
        .menu__horizontal {
            ul {
                li {
                    a {
                        color: $white;
                        &:hover,
                        &:focus,
                        &:active {
                            background: rgba(210, 228, 255, 0.04);
                        }
                    }
                }
            }
        }

        li {
            a {
                color: $lighterGrey;
                svg {
                    * {
                        fill: $lighterGrey;
                        transition: fill $darkModeTransitionTimer $sexyEase;
                    }
                }
            }
        }
    }
}
