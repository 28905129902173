.layout--hero-pricing-plans {
    min-height: 70vw;

    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
        text-transform: uppercase;
        font-family: $tertiaryFont;
        font-weight: 500;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        &.is-very-loud {
            font-size: calc(32rem/16);
        }

        p {
            font-size: 100%;
            margin: 0;
        }

        span {
            background: linear-gradient(to right, $globalCurrentEvents, $sitePrimaryLightest, $globalCurrentEvents);
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
            font-weight: bold;
        }

        svg {
            width: calc(27rem/16);
            height: calc(27rem/16);
            transform: translateY(calc(2rem/16)) translateX(calc(-1rem/16));
            g {
                * {
                    fill: $white;
                }
            }

            & > circle {
                stroke: $white;
            }
        }

        @media screen and (min-width: calc(404em/16)) {
            font-size: calc(35rem/16);
            max-height: calc(1200rem/16);
            &.is-very-loud {
                font-size: calc(45rem/16);
                margin: 1rem 0 1.5rem 0;
                svg {
                    width: calc(35rem/16);
                    height: calc(35rem/16);
                    transform: translateY(calc(2rem/16));
                }
            }
        }

        @media screen and (min-width: $breakpointTablet) {
            &.is-very-loud {
                font-size: calc(45rem/16);
                svg {
                    width: calc(36rem/16);
                    height: calc(36rem/16);
                    //transform: translateY(calc(3rem/16));
                }
            }
        }

        @media screen and (min-width: $breakpointDesktop) {
            font-size: calc(40rem/16);
            &.is-very-loud {
                font-size: calc(55rem/16);
                svg {
                    width: calc(44rem/16);
                    height: calc(44rem/16);
                }
            }
        }

        @media screen and (min-width: $breakpointWide) {
            font-size: calc(45rem/16);
            &.is-very-loud {
                font-size: calc(80rem/16);
                svg {
                    width: calc(62rem/16);
                    height: calc(62rem/16);
                }
            }
        }
    }

    .hero__inner {
        position: relative;
        z-index: 1;
        padding: 1rem;
        text-align: center;
    }

    .hero__tagline {
        font-size: calc(14rem/16);
        max-width: calc(350rem/16);
        margin: 1rem auto;
    }

    ul, li {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        margin: 0 auto;
        max-width: calc(240rem/16);
        .hero__icon-wrapper {
            border-radius: 50%;
            background: rgba(217,178,255,0.1);
            height: calc(48rem/16);
            width: calc(48rem/16);
            box-shadow: inset 0 0 0 calc(2rem/16) rgba(255,255,255,0.1);
            margin: 0 calc(4rem/16) calc(10rem/16) 0;
        }

        svg {
            height: 100%;
            width: 100%;
            padding: calc(10rem/16);
            * {
                fill: $siteSecondary;
            }
        }
    }

    .hero__background {
        opacity: 0.3;
    }

    .hero__plans {
        padding: 1rem;
        .hero__single-plan {
            max-width: calc(300rem/16);
            margin: 0 auto;
            border-radius: calc(16rem/16);
            box-shadow: 0 0 0 calc(2rem/16) rgba(255,255,255,0.2);
            & > p {
                margin: 0;
                font-weight: 500;
                text-transform: uppercase;
                padding: calc(5rem/16) calc(10rem/16);
                border-top-left-radius: calc(16rem/16);
                border-top-right-radius: calc(16rem/16);
            }

            .plan__inner {
                box-shadow: 0 calc(-1rem/16) 0 0 rgba(255,255,255,0.2);
                padding: calc(16rem/16);
                border-bottom-left-radius: calc(16rem/16);
                border-bottom-right-radius: calc(16rem/16);
                text-align: left;
                backdrop-filter: blur(10px); /* Apply blur to the background behind this box */
                -webkit-backdrop-filter: blur(10px); /* Safari support */
                p {
                    margin: 0;
                }

                h2 {
                    font-family: $tertiaryFont;
                    font-weight: bold;
                    font-size: calc(45rem/16);
                    line-height: 1;
                    margin: 0 0 1rem 0;
                    span {
                        font-weight: normal;
                        font-size: 0.4em;
                    }
                }

                ul {
                    margin: 0 0 2rem 0;
                    display: block;
                    li {
                        width: 100%;
                        text-align: left;
                        display: block;
                        padding: 0;
                        font-size: calc(12rem/16);
                    }
                }

                .ickonic-button {
                    margin: 0 auto;
                    width: 100%;
                    padding: calc(8rem/16);
                    text-shadow: calc(2rem/16) calc(2rem/16) calc(2rem/16) rgba(0, 0, 0, 0.3);
                }
            }

            &:last-child {
                margin: 1rem auto 0 auto;
                & > p {
                    background: $white;
                    color: $black;
                }
            }
        }
    }

    .layout__gradient-transition {
        position: absolute;
        left: 0;
        bottom: 0;
        height: calc(100rem/16);
        width: 100%;
        background: #03031c;
        background: linear-gradient(0deg, #03031c 0%, rgba(32,32,32,0) 100%);
    }

    @media screen and (min-width: calc(404em/16)) {
        .hero__inner {
            padding: 2rem 1rem 1rem 1rem;
        }

        .hero__tagline {
            margin-bottom: 1.5rem;
        }

        ul {
            display: flex;
            align-items: flex-start;
        }

        li {
            padding: calc(8rem/16);
            width: 33.333333%;
            font-size: calc(12rem/16);
        }

        .hero__plans {
            display: flex;
            justify-content: space-between;
            padding: 0;
            margin: 2rem 0 0 0;
            .hero__single-plan {
                margin: 0;
                width: calc(50% - calc(8rem/16));
                .plan__inner {
                    p {
                        font-size: calc(12rem/16);
                        margin: 0 0 calc(10rem/16) 0;
                    }

                    h2 {
                        font-size: calc(25rem/16);
                        span {
                            font-weight: normal;
                            font-size: 0.6em;
                        }
                    }
                }

                &:last-child {
                    margin: 0;
                }
            }
        }
    }

    @media screen and (min-width: $breakpointSmallTablet) {
        .hero__tagline {
            max-width: calc(450rem/16);
            font-size: calc(16rem/16);
        }

        ul {
            display: block;
        }

        li {
            width: auto;
            flex-direction: row;
            max-width: unset;
            padding: calc(6rem/16);
            font-size: calc(18rem/16);
            .hero__icon-wrapper {
                height: calc(32rem/16);
                width: calc(32rem/16);
                margin: 0 calc(10rem/16) 0 0;
            }

            svg {
                padding: calc(6rem/16);
            }
        }

        .hero__plans {
            display: flex;
            justify-content: center;
            margin: 3rem 0 0 0;
            .hero__single-plan {
                margin: 0;
                width: calc(250rem/16);
                .plan__inner {
                    p {
                        font-size: calc(14rem/16);
                    }

                    h2 {
                        font-size: calc(35rem/16);
                        span {
                            font-size: 0.5em;
                        }
                    }
                }

                &:last-child {
                    margin: 0 0 0 1rem;
                }
            }
        }
    }

    @media screen and (min-width: $breakpointTablet) {
        .hero__tagline {
            max-width: unset;
        }

        .hero__plans {
            .hero__single-plan {
                width: calc(300rem/16);
            }
        }
    }

    @media screen and (min-width: $breakpointWide) {
        .hero__inner {
            transform: translateY(-3rem);
        }
    }

    @media screen and (min-width: calc(1360em/16)) {
        height: calc(952rem/16);
        min-height: unset;
    }
}
