.layout--feature-roadmap {
    .layout__inner {
        padding: 2rem 0;
        max-width: calc(1600rem/16);
        margin: 0 auto;
    }

    .layout__scroll-wrapper {
        overflow-x: auto;
        display: flex;
    }

    .layout__instructions {
        text-align: center;
        font-size: calc(14rem/16);
        text-transform: uppercase;
        width: 100%;
        letter-spacing: calc(2rem/16);
        display: flex;
        align-items: center;
        justify-content: center;
        div {
            height: calc(24rem/16);
            width: calc(24rem/16);
            animation: translateLeft 2s infinite ease-in-out;
            svg {
                * {
                    fill: $siteAccent;
                }
            }

            &:last-of-type {
                animation: translateRight 2s infinite ease-in-out;
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .layout__time-frame {
        > h4 {
            padding: calc(5rem/16) calc(10rem/16) calc(4rem/16) calc(10rem/16);
            box-shadow: inset 0 0 0 calc(1rem/16) rgba(255,255,255,0.2);
            text-transform: uppercase;
            display: inline-block;
            font-family: $baseFont;
            letter-spacing: calc(2rem/16);
        }

        .layout__features {
            display: flex;
            .layout__item {
                max-width: calc(400rem/16);
                margin: 1rem 0 2rem 0;
                padding: 1rem;
                box-shadow: inset calc(1rem/16) 0 0 0 rgba(255,255,255,0.2);
                //height: calc(210rem/16);
                display: flex;
                align-items: flex-start;
                .layout__icon {
                    width: calc(50rem/16);
                    height: calc(50rem/16);
                    padding: calc(10rem/16);
                    border-radius: 50%;
                    margin-right: calc(16rem/16);
                    box-shadow: inset 0 0 0 calc(2rem/16) $siteAccent;
                }
                svg {
                    width: 100%;
                    height: 100%;
                }

                .layout__content {
                    width: calc(100% - (76rem/16));
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-between;
                    .layout__heading {
                        min-height: calc(48rem/16);
                        display: flex;
                        align-items: center;
                        h3 {
                            text-transform: uppercase;
                            font-family: $tertiaryFont;
                            font-weight: bold;
                            font-size: calc(20rem/16);
                            letter-spacing: calc(2rem/16);
                            margin: 0;
                        }
                    }


                    p {
                        font-family: $tertiaryFont;
                        font-size: calc(14rem/16);
                    }
                }
            }
        }
    }
}
