.form--cancel-or-pause {
    background: darken($darkerGrey, 3%);
    border-radius: calc(10rem/16);
    width: 100%;
    max-width: calc(600rem/16);
    max-height: calc(100vh - 2rem);
    overflow-y: auto;

    // Select input's dropdowns
    .react-select__menu {
        position: relative;
    }

    .cancel-or-pause__heading-wrapper {
        padding: 1rem;
        h1 {
            font-family: $tertiaryFont;
            margin: 0;
            text-align: center;
            font-size: calc(25rem/16);
            @media screen and (min-width: $breakpointSmallTablet) {
                font-size: calc(35rem/16);
            }
        }
    }

    .cancel-or-pause__divider {
        height: calc(2rem/16);
        background: $blueToPurpleGradient;
    }

    .cancel-or-pause__content-wrapper {
        padding: 1rem;
        p {
            min-height: calc(30rem/16);
            font-size: calc(14rem/16);
        }

        .ickonic-button {
            color: $white;
            background: $hiddenKnowledge;
            &.is-loading {
                width: calc(168rem/16);
            }

            &.is-not-valid {
                color: $midGrey;
                background: $darkGrey;
                cursor: default;
                &:hover,
                &:focus,
                &:active {
                    background: $darkGrey;
                }
            }

            svg {
                padding: calc(4rem/16);
                animation: spin infinite $sexyEase 1s;
                margin: 0;
            }

            &:hover,
            &:focus,
            &:active {
                background: $siteError;
            }
        }
    }

    @media screen and (min-width: $breakpointSmallTablet) and (min-height: $breakpointDesktop) {
        .cancel-or-pause__heading-wrapper,
        .cancel-or-pause__content-wrapper {
            padding: 2rem;
        }
    }
}
