.category-picker {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(60rem/16);
    .category-picker__toggle {
        position: relative;
        z-index: 1;
        padding: calc(10rem/16);
        background: $white;
        box-shadow: 0 calc(-1rem/16) calc(2rem/16) 0 rgba(0,0,0,0.1);
        button {
            text-transform: uppercase;
            background: $darkGrey;
            color: $white;
            max-width: calc(300rem/16);
            box-shadow: 0 0 calc(2rem/16) calc(1rem/16) rgba(0,0,0,0.1);
            &:hover,
            &:active,
            &:focus {
                background: $darkGrey;
                color: $white;
            }
        }
    }

    .category-picker__list {
        position: absolute;
        bottom: calc(60rem/16);
        left: 0;
        width: 100%;
        padding: calc(20rem/16) calc(15rem/16);
        box-shadow: 0 0 calc(2rem/16) 0 rgba(0,0,0,0.2);
        background: $white;
        transform: translateY(100%);
        transition: transform .25s $sexyEase;
        .category-picker__option {
            cursor: pointer;
            border-radius: calc(5rem/16);
            padding: 0 calc(5rem/16);
            &:hover,
            &:focus,
            &:active {
                background: $lightestGrey;
                label {
                    padding: calc(8rem/16) calc(1rem/16) calc(8rem/16) calc(19rem/16);
                }

                .category-picker__colour {
                    left: calc(4rem/16);
                }
            }
        }

        .category-picker__option {
            transition: padding .15s $sexyEase;
        }

        label {
            color: $darkestGrey;
            font-weight: 500;
            font-size: calc(16rem/16);
            cursor: pointer;
            z-index: 1;
            display: block;
            padding: calc(8rem/16) calc(5rem/16) calc(8rem/16) calc(15rem/16);
            transition: padding .15s $sexyEase, background .15s $sexyEase;
        }

        .category-picker__colour {
            width: calc(8rem/16);
            height: calc(8rem/16);
            position: absolute;
            top: calc(18rem/16);
            left: calc(0rem/16);
            border-radius: calc(4rem/16);
            transition: left .15s $sexyEase, top .15s $sexyEase;
        }

        &.is-open {
            transform: translateY(0);
        }
    }

    &.using-desktop-mode {
        position: static;
        height: auto;
        background: none !important;
        max-width: calc(1358rem/16);
        margin: 0 auto;
        .category-picker__list {
            position: static;
            transform: translateY(0);
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            transition: none;
            padding: 0;
            background: none !important;
            box-shadow: 0 0 0 calc(1rem/16) rgba(0,0,0,0.1);
            border-radius: calc(20rem/16);
        }

        .category-picker__option {
            width: 11.111112%;
            padding: calc(8rem/16) calc(10rem/16);
            border-radius: 0;
            background: $white;
            position: relative;
            overflow: hidden;
            &:first-child {
                border-top-left-radius: calc(20rem/16);
                border-bottom-left-radius: calc(20rem/16);
            }

            &:last-child {
                border-top-right-radius: calc(20rem/16);
                border-bottom-right-radius: calc(20rem/16);
            }

            &:hover,
            &:focus,
            &:active {
                padding: calc(6rem/16) calc(10rem/16) calc(10rem/16) calc(10rem/16);
                background: $white;
                label {
                    padding: 0;
                    .category-picker__colour {
                        top: calc(100% - (4rem/16));
                    }
                }
            }
        }

        .category-picker__colour {
            position: absolute;
            top: calc(100% - (2rem/16));
            left: 0 !important;
            width: 100%;
            height: 100%;
            transform: rotate(0);
        }

        label {
            width: 100%;
            display: block;
            text-align: center;
            padding: 0;
            transition: none;
            font-size: calc(12rem/16);
            position: static;
            span {
                position: relative;
                z-index: 1;
            }
        }

        @media screen and (min-width: $breakpointWide) {
            .category-picker__option {
                &:first-child {
                    border-top-left-radius: calc(20rem / 16);
                    border-bottom-left-radius: calc(20rem / 16);
                }

                &:last-child {
                    border-top-right-radius: calc(20rem / 16);
                    border-bottom-right-radius: calc(20rem / 16);
                }
            }

            label {
                font-size: calc(14rem/16);
            }
        }
    }

    &.is-dark-mode {
        .category-picker__toggle {
            background: $darkModeMid;
            button {
                background: lighten($darkModeLighter, 10%);
                &:hover,
                &:active,
                &:focus {
                    background: lighten($darkModeLighter, 10%);
                }
            }
        }

        .category-picker__option {
            &:hover,
            &:focus,
            &:active {
                background: $darkModeMid;
            }
        }

        .category-picker__list {
            background: $darkModeLight;
            box-shadow: 0 0 calc(3rem/16) calc(2rem/16) rgba(0,0,0,0.2);
            label {
                color: $white;
            }
        }

        &.using-desktop-mode {
            .category-picker__option {
                background: $darkModeLight;
                &:hover,
                &:focus,
                &:active {
                    background: $darkModeLight;
                }
            }
        }
    }
}
