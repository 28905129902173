@font-face {
    font-family: "WorkSans";
    src: local("WorkSans"),
    url("../../Assets/Fonts/WorkSans-VariableFont_wght.ttf") format("truetype");
}

@font-face {
    font-family: "Rubik";
    src: local("Rubik"),
    url("../../Assets/Fonts/Rubik-VariableFont_wght.ttf") format("truetype");
}

@font-face {
    font-family: "Outfit";
    src: local("Outfit"),
    url("../../Assets/Fonts/Outfit-VariableFont_wght.ttf") format("truetype");
}
