.layout--single-plan-info {
    padding: calc(3rem/16);
    border-radius: 0 calc(10rem/16) calc(10rem/16) calc(10rem/16);
    background: $lightestGrey;
    margin: 4rem 0 2rem 0;
    position: relative;
    .single-plan-info__inner {
        padding: 1rem;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .single-plan-info__label {
            border-radius: calc(10rem/16) calc(10rem/16) 0 0;
            background: $lightestGrey;
            position: absolute;
            top: calc(-32rem/16);
            padding: calc(10rem/16) calc(16rem/16);
            text-align: center;
            left: 0;
            margin: 0;
            font-size: calc(14rem/16);
            height: auto;
            span {
                display: inline-block;
                min-width: calc(100rem/16);
                height: calc(12rem/16);
                margin: 0 0 0 calc(5rem/16);
            }
        }

        p {
            margin: 0 0 calc(8rem/16) 0;
            width: 100%;
            font-size: calc(14rem/16);
            display: flex;
            min-height: calc(28rem/16);
            justify-content: space-between;

            span {
                display: inline-block;
                padding: calc(7rem/16) calc(10rem/16);
                line-height: 1;
                height: calc(28rem/16);
                border-radius: calc(5em/16);
                font-size: calc(14rem/16);
                font-weight: bold;
                background: none;
                color: $white;
                background: $darkModeLighter;
                &.single-plan-info__span {
                    margin: 0 0 0 calc(7rem/16);
                }
            }


            &:nth-child(even) {
                span {

                }
            }

            &:last-of-type {
                margin: 0;
            }
        }
    }

    @media screen and (min-width: $breakpointSmallTablet) {
        .single-plan-info__inner {
            p {
                //width: 50%;
                //margin: 0;
                //justify-content: flex-start;
            }
        }
    }

    &.is-dark-mode {
        background: $darkModeMid;
        .single-plan-info__inner {
            .single-plan-info__label {
                background: $darkModeMid;
            }
        }
    }
}
