.lifetime-membership__svg-wrapper {
    overflow: hidden;
    position: relative;
    padding: 2rem 0;
    background: $black;
    .ickonic-logo--primary {
        position: absolute;
        top: calc(3rem/16);
        left: calc(50% - (56rem/16));
        z-index: 10;
        width: calc(112rem/16);
        height: calc(47rem/16);
        padding: calc(4rem/16);
        * {
            fill: rgba(255,255,255,0.7);
        }
    }

    .vector--play-button-fractal-lines {
        width: 400vw;
        height: 400vw;
        position: absolute;
        top: -80vw;
        left: -120vw;
        z-index: 0;
        path {
            stroke: rgba(187,54,184,0.035);
            transition: stroke 1.5s $sexyEase;
        }

        @media screen and (min-width: $breakpointSmallTablet) {
            width: 300vw;
            height: 300vw;
            top: -100vw;
            left: -90vw;
        }

        @media screen and (min-width: $breakpointWide) {
            width: 250vw;
            height: 250vw;
            top: -80vw;
            left: -70vw;
        }

        @media screen and (min-width: $breakpointSuperWide) {
            top: -90vw;
        }
    }

    .lifetime-membership--hero {
        max-width: calc(800rem/16);
        padding: 0 0 1rem 0;
        margin: 0 auto;
        .ickonic-button {
            animation: boxShadowGlowPulse 1.5s infinite ease-in-out;
            position: relative;
            z-index: 1;
        }

        @media screen and (min-width: $breakpointDesktop) {
            padding: 7rem 1rem 6rem 1rem;
            max-width: none;
        }
    }
}
