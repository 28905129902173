.layout--app-grid {
    background: #010107;
    padding: 1rem;
    h1 {
        font-family: $tertiaryFont;
        text-transform: uppercase;
        font-weight: bold;
        padding: 0 1rem 1rem 1rem;
        text-align: center;
        margin: 0;
        span {
            display: inline-block;
            background: linear-gradient(180deg, #4676E6 0%, #b760b4 100%);
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
        }

        @media screen and (min-width: $breakpointLargeMobile) {
            font-size: calc(35rem/16);
        }

        @media screen and (min-width: $breakpointDesktop) {
            font-size: calc(40rem/16);
        }

        @media screen and (min-width: $breakpointWide) {
            font-size: calc(45rem/16);
        }
    }

    .app-grid__grid {
        padding: 1rem;
        .app-grid__grid-item {
            border-radius: calc(10rem/16);
            background: rgba(119, 127, 161, 0.25);
            box-shadow: 0 0 0 calc(1rem/16) rgba(119, 127, 161, 0.4);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: calc(100vw - 5rem);
            margin: 0 0 1rem 0;
            color: $white;
            &:last-child {
                margin: 0;
            }

            svg,
            span {
                display: inline-block;
                margin: 0 calc(2rem/16);
            }
        }
    }

    @media screen and (min-width: $breakpointSmallMobile) {
        .app-grid__grid {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
            .app-grid__grid-item {
                width: calc(50% - (8rem/16));
                height: calc(50vw - 3rem);
                &:last-child {
                    width: 100%;
                }
            }
        }
    }

    @media screen and (min-width: $breakpointTablet) {
        .app-grid__grid {
            .app-grid__grid-item {
                width: calc(50% - (8rem/16));
                height: calc(33.333333vw - 3rem);
                &:nth-child(3),
                &:nth-child(4),
                &:last-child {
                    width: calc(33.333333% - (12rem/16));
                    height: calc(33.333333vw - 2.5rem);
                }
            }
        }
    }

    @media screen and (min-width: $breakpointDesktop) {
        padding: 2rem 0 4rem 0;
        .app-grid__grid {
            .app-grid__grid-item {
                width: calc(20% - (8rem/16)) !important;
                height: calc(20vw - 2rem) !important;
            }
        }
    }

    @media screen and (min-width: calc(1347em/16)) {
        .app-grid__grid {
            justify-content: center;
            padding: 1rem 0;
            .app-grid__grid-item {
                margin: 0 calc(5rem/16) !important;
                width: calc(251rem/16) !important;
                height: calc(237rem/16) !important;
            }
        }
    }
}
