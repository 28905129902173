// Headings
// ==========================================================================

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.editor-post-title__input {
    font-family: $secondaryFont;
    font-weight: normal;
    margin: 1rem 0;
    line-height: 1.2;
    &.has-alt-font {
        font-family: $baseFont;
    }
}

h1,
.h1 {
    font-size: calc(32rem/16);
    @media screen and (min-width: $breakpointTablet) {
        font-size: calc(36rem/16);
    }
}

h2,
.h2 {

    font-size: calc(20rem/16);
    @media screen and (min-width: $breakpointTablet) {
        font-size: calc(26rem/16);
    }
}

h3,
.h3 {
    font-size: calc(16rem/16);
    @media screen and (min-width: $breakpointTablet) {
        font-size: calc(18rem/16);
    }
}

h4,
.h4 {
    font-size: calc(14rem/16);
    @media screen and (min-width: $breakpointTablet) {
        font-size: calc(16rem/16);
    }
}

h5,
.h5 {
    font-size: calc(12rem/16);
    @media screen and (min-width: $breakpointTablet) {
        font-size: calc(14rem/16);
    }
}
