.layout--category-featured-items {
    padding: 1rem 1.5rem;
    .category-featured-items__content {
        width: auto;
        display: table;
        max-width: calc(500rem/16);
        padding: 0 0 2rem 0;
        position: relative;
        z-index: 3;
        h1 {
            margin-top: 0;
            text-transform: uppercase;
            font-weight: 500;
            font-size: calc(22rem/16);
        }

        .ickonic-button {
            font-weight: normal;
            color: $white;
            margin: 1.5rem 0 2rem 0;
            padding: calc(10rem/16) calc(25rem/16);
            border-radius: calc(25rem/16);
            &.has-dark-theme {
                color: $black;
            }

            svg {
                margin: 0 0 0 calc(10rem/16);
                width: calc(12rem/16);
                height: calc(12rem/16);
            }
        }
    }

    .category-featured-items__wrapper {
        position: relative;
        z-index: 2;
    }

    .category-featured-items__inner {
        max-width: calc(1300rem/16);
        margin: 0 auto;
    }

    .category-featured-items__focus-image {
        display: none;
        &.show-on-mobile {
            display: inherit;
            top: 0;
            opacity: 0.3;
        }
    }

    .category-featured-items__gradient-overlay-top,
    .category-featured-items__gradient-overlay-bottom {
        z-index: 1;
        height: calc(30rem/16);
    }

    .category-featured-items__gradient-overlay-bottom {
        top: unset;
        bottom: 0;
    }

    .category-featured-items__item {
        position: relative; // Ensure label positioning works
        border-radius: calc(20rem/16);
        margin: 0 0 1rem 0;
        z-index: 2;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 80%;
            background: rgba(15,15,28,0.25);
            border-bottom-right-radius: calc(20rem/16);
            border-bottom-left-radius: calc(20rem/16);
            backdrop-filter: blur(10px); /* Apply blur to the background behind this box */
            -webkit-backdrop-filter: blur(10px); /* Safari support */
        }

        .category-featured-items__item-image {
            border-top-left-radius: calc(15rem/16);
            border-top-right-radius: calc(15rem/16);
        }

        .category-featured-items__item-free-view {
            top: calc(6rem/16);
            z-index: 2;
        }

        .category-featured-items__item-content {
            padding: 1rem 3rem 1rem 1rem;
            position: relative;
            z-index: 1;
            h3 {
                margin: 0;
                font-family: $tertiaryFont;
                a {
                    color: $white;
                }

                @media screen and (min-width: $breakpointLargeMobile) {
                    font-size: calc(20rem/16);
                }
            }

            svg {
                position: absolute;
                top: calc(20rem/16);
                right: calc(16rem/16);
                width: calc(16rem/16);
                height: calc(16rem/16);
            }
        }
    }

    @media screen and (min-width: $breakpointSmallMobile) {
        padding: 2rem 1.5rem 1rem 1.5rem;
        .category-featured-items__wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .category-featured-items__content {
            h1 {
                font-size: calc(25rem / 16);
            }
        }

        .category-featured-items__item {
            width: calc(50% - (8rem/16));
        }
    }

    @media screen and (min-width: $breakpointSmallTablet) {
        .category-featured-items__content {
            h1 {
                font-size: calc(30rem / 16);
            }
        }
    }

    @media screen and (min-width: calc(850em/16)) {
        padding: 4rem 1rem 3rem 1rem;
        &.has-content-right {
            .category-featured-items__content {
                margin: 0 0 0 auto;
            }
        }

        &.has-content-left {
            .category-featured-items__focus-image {
                &.show-on-mobile,
                &.dont-show-on-mobile {
                    left: unset;
                    right: 0;
                }
            }
        }

        .category-featured-items__content {
            padding: 0 0 5rem 0;
            width: calc(400rem/16);
            h1 {
                font-size: calc(35rem/16);
            }

            .ickonic-button {
                padding: calc(16rem/16) calc(35rem/16);
                border-radius: calc(30rem/16);
            }
        }

        .category-featured-items__focus-image {
            &.show-on-mobile,
            &.dont-show-on-mobile {
                display: inherit;
                top: 0;
                max-height: calc(1200rem/16);
                max-width: calc(900rem/16);
                opacity: 1;
                width: calc(100% - (400rem/16));
                height: 100vw;
            }
        }

        .category-featured-items__item {
            width: calc(25% - (8rem/16));
        }
    }

    @media screen and (min-width: $breakpointDesktop) {
        padding: 6rem 1rem 5rem 1rem;
        .category-featured-items__gradient-overlay-top,
        .category-featured-items__gradient-overlay-bottom {
            height: calc(60rem/16);
        }

        .category-featured-items__item {
            width: calc(25% - (16rem/16));
        }
    }

    @media screen and (min-width: $breakpointWide) {
        &.has-content-left {
            .category-featured-items__focus-image {
                &.show-on-mobile,
                &.dont-show-on-mobile {
                    right: calc(50% - (650rem/16));
                }
            }
        }

        .category-featured-items__focus-image {
            &.show-on-mobile,
            &.dont-show-on-mobile {
                left: calc(50% - (650rem/16));
            }
        }
    }
}
