.video-player__big-centered {
    opacity: 0;
    transition: opacity 1s $sexyEase;
    z-index: 1;
    .big-centered__inner,
    .big-centered__skip-controls {
        display: flex;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -60%);
    }

    .big-centered__inner {
        justify-content: center;
        cursor: pointer;
        z-index: 2;
        width: calc(80rem/16);
        height: calc(80rem/16);
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.6);
        svg {
            width: calc(35rem/16);
            height: calc(35rem/16);
            &.ickonic-logo--icon {
                width: calc(50rem/16);
                height: calc(50rem/16);
            }

            & > g {
                fill: $white;
                & > circle {
                    opacity: 0;
                }
            }

            & > circle {
                opacity: 0;
            }
        }
    }

    &.is-not-hidden {
        transition: opacity .25s $sexyEase;
        opacity: 1;
    }

    .big-centered__skip-controls {
        position: absolute;
        z-index: 1;
        width: calc(180rem/16);
        justify-content: space-between;
        button {
            width: calc(45rem/16);
            height: calc(45rem/16);
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.6) !important;
            padding: calc(9rem/16);
            svg {
                width: 100%;
                height: 100%;
                * {
                    fill: $white;
                }
            }
        }
    }

    @media screen and (min-width: $breakpointDesktop) {
        .big-centered__inner,
        .big-centered__skip-controls {
            transform: translate(-50%, -50%);
        }
    }
}
