// Grouping content
// ==========================================================================
ul,
ol {
    padding: 0 0 0 1rem;
    margin: 1rem 0 1rem 1rem;
    ul,
    ol {
        margin: 0 0 0 1rem;
    }
}

// Contain overflow in all browsers.
pre {
    overflow: auto;
}

// Address odd `em`-unit font size rendering in all browsers.
code,
kbd,
pre,
samp {
    color: $codeColor;
    font-family: $codeFontFamily;
    font-size: $codeFontSize;
}

blockquote {
    margin: 2rem 0;
    padding: 2rem calc(100rem/16) 2rem 2rem;
    font-size: calc(20rem/16);
    font-style: italic;
    color: $sitePrimary;
    background: white;
    line-height: 1.5;
    position: relative;
    overflow: hidden;
    box-shadow: inset 0 0 0 calc(1rem/16) $sitePrimary;
    &:after {
        content: '"';
        color: $sitePrimary;
        position: absolute;
        top: 0;
        right: 0;
        font-size: calc(100rem/16);
        height: 100%;
        width: calc(70rem/16);
        text-align: left;
        line-height: 1.1;
    }
}

code {
    color: $white;
    padding: calc(4rem/16) calc(7rem/16);
    font-family: $codeFont;
    font-size: calc(16rem/16);
    display: inline-block;
    font-weight: bold;
    background: linear-gradient(45deg, $sitePrimary 0%, darken($sitePrimary, 5%) 100%);
    border-radius: calc(3rem/16);
    line-height: 1.2;
}

p {
    margin: 1rem 0;
    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: $breakpointDesktop) {
        font-size: calc(18rem/16);
    }
}
