.lifetime-membership--roadmap {
    background: rgba(25, 25, 25, 1);
    padding: 1rem 1rem 2rem 1rem;
    .button-ickonic {
        margin-top: 2rem !important;
    }

    @media screen and (min-width: $breakpointTablet) {
        padding: 3rem;
    }
}
