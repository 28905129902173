.page--login {
    padding: 0 0 1rem 0;
    background: $white;
    border-radius: calc(16rem/16);
    .form--login {
        padding: 1rem;
        width: 100%;
        margin: 0 auto;
        max-width: calc(550rem/16);
        h1 {
            text-align: left;
            font-family: $baseFont;
            font-weight: 900;
            font-size: calc(24rem/16);
            margin: 1rem 0;
        }

        .form__input-text {
            margin: 0 0 1rem 0;
        }

        button {
            text-transform: uppercase;
            margin: 1rem 0 0 0;
            width: 100%;
            display: block;
            background: $sitePrimaryLightest;
            box-shadow: 0 calc(3rem/16) calc(2rem/16) rgba(0,0,0,0.2);
            &:hover,
            &:focus,
            &:active {
                background: $sitePrimary;
            }
        }
    }

    @media screen and (min-width: $breakpointSmallMobile) {
        .form--login {
            h1 {
                margin: 0.5rem 0 1.5rem 0;
            }
        }
    }

    @media screen and (min-width: $breakpointLargeMobile) {
        .form--login {
            h1 {
                font-size: calc(32rem/16);
            }
        }
    }

    &.is-dark-mode {
        background: $darkModeMid;
    }
}
