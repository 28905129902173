.u-relative_hidden {
    position: relative;
    overflow: hidden;
}

.u-full_cover_absolute {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.u-important {
    padding: 1rem 1rem 1rem calc((10rem/16) + 1rem);
    font-weight: 700;
    font-size: calc(22rem/16);
    line-height: 1.4;
    box-shadow: inset calc(10rem/16) 0 0 0 $sitePrimary, inset 0 0 0 calc(2rem/16) $sitePrimary;
    background: $lightestGrey;
    color: $sitePrimary;
    font-family: $secondaryFont;
    font-style: italic;
    margin: 3rem 0;
}

.u-no_margins {
    padding: 0 !important;
    margin: 0 !important;
}

.u-text_transform_uppercase {
    text-transform: uppercase !important;
}

.u-text_align_center {
    text-align: center;
}

.u-text_decoration_none {
    text-decoration: none !important;
    a {
        text-decoration: none !important;
    }
}
