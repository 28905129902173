.layout--articles-vertical {
    padding: 2rem 1rem 1rem 1rem;
    h2 {
        margin: 0;
        text-transform: uppercase;
        color: $black;
        font-weight: 600;
        font-size: calc(18rem/16);
        letter-spacing: calc(1rem/16);
    }

    .articles-vertical__article {
        display: flex;
        align-items: flex-start;
        margin: 0;
        padding: 1.5rem 0 2rem 0;
        box-shadow: 0 calc(1rem/16) 0 0 $midGrey;
        min-height: calc(100rem/16);
        &:last-child {
            box-shadow: none;
        }

        .articles-vertical__thumbnail {
            position: relative;
            width: calc(80rem/16);
            height: calc(80rem/16);
            margin: 0 1rem 0 0;
            transform: translateY(calc(2rem/16));
            border-radius: calc(5rem/16);
            overflow: hidden;
        }

        .articles-vertical__content {
            width: calc(100% - (96rem/16));
            line-height: 1.3;

            span,
            div {
                display: block;
                margin: 0 0 calc(8rem/16) 0;
                &.u-relative_hidden {
                    width: 100%;
                    height: calc(25rem/16);
                }
            }

            // Title
            & > a {
                color: $black;
                font-weight: 600;
                font-size: calc(12rem/16);
                &:hover,
                &:focus,
                &:active {
                    text-decoration: underline;
                }
            }

            // Author and date
            p {
                margin: calc(10rem/16) 0 0 0;
                font-size: calc(12rem/16);
                a {
                    color: $darkGrey;
                }
            }
        }
    }

    @media screen and (min-width: $breakpointTablet) {
        padding: 0 1rem;
    }

    @media screen and (min-width: $breakpointDesktop) {
        padding: 0 1rem 0 2rem;
        .articles-vertical__article {
            .articles-vertical__content {

                // Title
                & > a {
                    font-size: calc(16rem/16);
                }

                // Author and date
                p {
                    font-size: calc(12rem/16);
                }
            }
        }
    }
}
