> .layout--hero-one-column {
    background: rgba(15,15,15, 1) !important;
}

.lifetime-membership__icon-grid {
    background: rgba(15,15,15, 1);
    position: relative;
    z-index: 1;
    padding: 2rem;
    border-radius: calc(20rem/16);
    .button-ickonic {
        margin-top: 2rem !important;
    }

    .icon-grid {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 1rem 0;
        .icon-grid__item {
            width: calc(50% - .5rem);
            padding: 1rem 0;
            p {
                font-size: calc(14rem/16);
                text-align: center;
                max-width: calc(230rem/16);
                margin: 1rem auto 0 auto;
            }

            svg {
                width: calc(45rem/16);
                height: calc(45rem/16);
                display: block;
                margin: 0 auto;
            }
        }
    }

    @media screen and (min-width: $breakpointTablet) {
        .icon-grid {
            padding: 2rem 0;
            .icon-grid__item {
                width: calc(20% - .5rem);

            }
        }
    }

    @media screen and (min-width: $breakpointDesktop) {
        padding: 3rem;
        .icon-grid {
            .icon-grid__item {
                width: calc(20% - .5rem);
                padding: 1rem;
                p {
                    font-size: calc(16rem / 16);
                }
            }
        }
    }
}
