.popup--progress-panel {
    transition: background .45s $sexyEase, transform .45s $sexyEase;
    &.is-transitioning-in {
        transition: background .45s $sexyEase .01s, transform .45s $sexyEase .01s;
        .popup__inner {
            opacity: 1;
            transform: scale(1);
            transition: opacity .45s $sexyEase .01s, transform .45s $sexyEase .01s;
        }
    }

    .popup__inner {
        padding: 1rem;
        width: 100%;
        max-width: calc(400rem/16);
        max-height: 80%;
        position: relative;
        opacity: 0;
        transform: scale(.95);
        overflow-y: auto;
        transition: opacity .25s $sexyEase, transform .25s $sexyEase;
        svg {
            width: calc(40rem/16);
            height: calc(40rem/16);
            margin: 0 auto 1rem auto;
            display: block;
            &.popup__loader {
                animation: spin 0.5s infinite linear;
            }

            * {
                fill: $white;
            }
        }

        p {
            text-align: center;
            font-size: calc(14rem/16);
            span {
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    width: calc(20rem/16);
                    height: calc(20rem/16);
                    margin: 0 calc(10rem/16) 0 0;
                }
            }
        }

        h1 {
            font-family: $tertiaryFont;
            text-align: center !important;
            font-size: calc(24rem/16) !important;
        }

        @media screen and (min-width: $breakpointSmallTablet) and (min-height: calc(1000em/16)) {
            margin-bottom: 20vh;
        }
    }

    .progress__bar-wrapper {
        position: relative;
        height: calc(8rem/16);
        border-radius: calc(4rem/16);
        overflow: hidden;
        width: 100%;
        margin: 0 auto;
        background: rgba(255,255,255,0.1);
        display: flex;
        .progress__segment {
            height: 100%;
            transition: width 0.25s $sexyEase;
            &--pending {
                background-color: $siteAccent; // Yellow for pending
            }

            &--success {
                background: $sitePrimaryLightest; // Green for success
            }

            &--error {
                background-color: $siteError; // Red for error
            }
        }
    }
}
