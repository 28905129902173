.lifetime-membership__letter {
    background: linear-gradient(90deg, rgba(229,174,44,1) 0%, rgba(187,54,184,1) 100%);
    h2 {
        margin-top: 0;
        font-family: $secondaryFont;
    }

    .letter__video {
        position: relative;
        width: 100%;
        > div {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    .letter__inner {
        max-width: calc(900rem/16);
        margin: 0 auto;
        padding: 1.5rem;
        background: rgba(0,0,0,0.8);
        p {
            font-family: $secondaryFont;
            &:first-of-type {
                margin-top: 0 !important;
            }

            &:last-of-type {
                margin-bottom: 0 !important;
            }
        }
    }

    @media screen and (min-width: $breakpointSmallTablet) {
        padding: 2rem;
        .letter__inner {
            border-radius: calc(10rem/16);
        }
    }

    @media screen and (min-width: $breakpointDesktop) {
        margin: 0;
    }
}
