.page--read {
    padding: 0 0 0 0;
    background: $lightestGrey;
    .page__heading-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem 0 1rem 0;
        .ickonic-button {
            margin: 0 0 0 calc(14rem/16);
        }

        h1 {
            margin: 0;
            padding: 0;
        }

        @media screen and (min-width: $breakpointTablet) {
            padding: 2rem 0;
        }
    }

    h1 {
        font-family: $tertiaryFont;
        color: $black;
        text-align: center;
        font-weight: bold;
        padding: 2rem 0 1rem 0;
        @media screen and (min-width: $breakpointTablet) {
            padding: 2rem 0 1rem 0;
            margin-bottom: 0;
        }
    }
}
