#root {
    // Opens/closes the main menu
    .button--burger {
        top: 0;
        left: 0;
        width: calc(40rem/16);
        height: $headerHeight;
        background: none;
        cursor: pointer;
        padding: 0;
        font-size: 0;
        z-index: 2;
        text-indent: -9999px;
        border: none;
        box-shadow: none;
        border-radius: 0;
        transition: background .25s $sexyEase, opacity .25s $sexyEase, transform .4s $sexyEase;
        opacity: 1;
        margin: 0;
        position: absolute;
        display: table;
        overflow: visible;
        &.is-desktop {
            display: none;
        }

        span {
            display: block;
            position: absolute;
            top: calc(50% - (1rem/16));
            right: calc(3rem/16);
            width: calc(20rem/16);
            height: calc(0rem/16);
            &:before,
            &:after {
                position: absolute;
                display: block;
                left: 0;
                width: 100%;
                height: calc(2rem/16);
                background: $black;
                content: "";
            }

            &:before {
                top: calc(3rem/16);
                transition: transform .25s $sexyEase, background .2s $sexyEase, top .25s $sexyEase;
            }

            &:after {
                bottom: calc(3rem/16);
                transition: transform .25s $sexyEase, background .2s $sexyEase, bottom .25s $sexyEase;
            }
        }

        &:focus,
        &:hover {
            outline: none;
            border: none;
            background: none;
            box-shadow: none;
        }

        &:hover,
        &:focus,
        &:hover {
            span {
                background: $black;
                &:before,
                &:after {
                    background: $black;
                }
            }
        }

        &.is-mobile {
            display: inherit;
        }

        &.menu-is-active {
            background: none;
            span {
                &:before,
                &:after {
                    background: $black;
                }

                &:before {
                    top: calc(-1rem/16);
                    transform: rotate(45deg);
                }

                &:after {
                    bottom: calc(-1rem/16);
                    transform: rotate(-45deg);
                }
            }
        }
    }

    .is-dark-mode {
        .button--burger {
            span {
                &:before,
                &:after {
                    background: $white;
                }
            }

            &:hover,
            &:focus,
            &:hover {
                span {
                    background: $white;
                    &:before,
                    &:after {
                        background: $white;
                    }
                }
            }

            &.menu-is-active {
                span {
                    &:before,
                    &:after {
                        background: $white;
                    }
                }
            }
        }
    }

}
