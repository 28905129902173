.watch-video__related-videos {
    padding: calc(8rem/16) 0 0 0;
    h2 {
        font-size: calc(18rem/16);
    }

    .carousel-landscape__item {
        & > a {
            text-align: left;

        }

        & > a:first-of-type,
        .is-sixteen-nine-aspect-ratio {
            border-radius: calc(8rem/16);
        }
    }
}

.is-theatre-mode {
    .watch-video__related-videos {
        h2 {
            margin-top: 0;
        }
    }
}
