.video-controls__settings-popup {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    overflow: hidden;
    border-radius: calc(8rem/16);
    width: calc(200rem/16);
    background: rgba(40,40,40,0.7);
    bottom: -10000%;
    transform: translateY(calc(-16rem/16));
    opacity: 0;
    padding: 0;
    right: calc(24rem/16);
    backdrop-filter: blur(calc(10rem/16));
    box-shadow: 0 0 calc(6rem/16) calc(1rem/16) rgba(0,0,0,0.5);
    -webkit-backdrop-filter: blur(calc(10rem/16));
    transition: height .3s $sexyEase, opacity .3s $sexyEase, bottom 0s $sexyEase .3s, transform .3s $sexyEase;
    &.is-open {
        opacity: 1;
        bottom: calc(100% + (5rem/16));
        transform: translateY(0);
        transition: height .3s $sexyEase, opacity .3s $sexyEase, bottom 0s $sexyEase, transform .3s $sexyEase;
    }

    .settings-popup__row {
        cursor: pointer;
        display: flex;
        padding: calc(8rem/16) calc(14rem/16) calc(8rem/16) calc(6rem/16);
        justify-content: space-between;
        align-items: center;
        background: rgba(255,255,255,0);
        transition: transform .25s $sexyEase;
        &.is-hidden {
            transform: translateX(-100%);
        }

        &:hover,
        &:active,
        &:focus {
            background: rgba(255,255,255,0.1);
        }

        p {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: calc(14rem/16);
            margin: 0;
            line-height: 1;
            svg {
                padding: calc(4rem/16);
                margin: 0 calc(5rem/16) 0 0;
            }
        }
    }

    .settings-popup__slide-in {
        position: absolute;
        left: 100%;
        bottom: 0;
        transition: left .25s $sexyEase;
        width: 100%;
        &.is-active {
            left: 0;
        }

        .settings-popup__submenu-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            padding: calc(2rem/16) calc(16rem/16);
            &:hover,
            &:active,
            &:focus {
                background: rgba(255,255,255,0.1);
            }

            &:first-of-type {
                padding: calc(4rem/16) calc(16rem/16) calc(2rem/16) calc(16rem/16);
            }

            &:last-of-type {
                padding: calc(2rem/16) calc(16rem/16) calc(10rem/16) calc(16rem/16);
            }

            svg {
                width: calc(20rem/16);
                height: calc(20rem/16);
                transform: translateY(calc(3rem/16)) translateX(calc(-3rem/16));
                &.is-active-icon {
                    width: calc(16rem/16);
                    height: calc(16rem/16);
                    transform: none;
                    * {
                        fill: $white;
                    }
                }
            }
        }
    }

    @media screen and (min-width: $breakpointSmallTablet) {
        //right: calc(80rem/16);
    }
}

.video-settings-portal-container {
    position: fixed;
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    opacity: 0;
    background: rgba(10,10,10,0.8);
    backdrop-filter: blur(calc(10rem/16));
    -webkit-backdrop-filter: blur(calc(10rem/16));
    transition: opacity .45s $sexyEase, height 0s linear .45s;
    &.is-open {
        opacity: 1;
        height: 100%;
        transition: opacity .45s $sexyEase, height 0s linear;
    }

    .video-controls__settings-popup {
        width: calc(100% - 2rem);
        bottom: -1000rem;
        transition: bottom 0s linear .45s, height .25s $sexyEase;
        //border-top-left-radius: calc(8rem/16);
        //border-top-right-radius: calc(8rem/16);
        //border-bottom-left-radius: 0;
        //border-bottom-right-radius: 0;
        &.is-open {
            transition: bottom 0s linear, height .25s $sexyEase;
            bottom: 40%;
            right: 1rem;
        }

        @media screen and (min-width: calc(430em/16)) {
            width: calc(398rem/16);
            left: calc(50% - (199rem/16));
        }
    }
}
