.page__lifetime-membership {

    /* General Elements
    ========================== */
    .button-ickonic--gradient {
        position: relative;
        z-index: 1;
        animation: boxShadowGlowPulseGold 1.5s infinite ease-in-out;
        &:hover,
        &:active,
        &:focus {
            transform: translateY(calc(-4rem/16));
        }
    }

    // Gradient Heading (H1)
    .lifetime-membership__heading {
        font-family: $tertiaryFont;
        text-align: center;
        font-weight: bold;
        position: relative;
        z-index: 1;
        background: linear-gradient(90deg, rgba(232,187,39,1) 0%, rgba(187,54,184,1) 100%);
        -webkit-background-clip: text;
        color: transparent;
    }

    @media screen and (min-width: $breakpointDesktop) {
        .lifetime-membership__heading {
            font-size: calc(40rem/16);
        }
    }

    @media screen and (min-width: $breakpointWide) {
        .lifetime-membership__heading {
            font-size: calc(44rem/16);
        }
    }

    /* Hero Section
    ========================== */
    @import './hero';

    /* Letter Section
    ========================== */
    @import './letter';

    /* Perks Section
    ========================== */
    @import './icon-grid';

    /* Roadmap
    ========================== */
    @import './roadmap';
}
