.page--watch-trailer {
    min-height: 0 !important;
    .page__wrapper {
        .watch__button {
            width: 100%;
            padding: 1rem 1rem 5rem 1rem;
            .ickonic-button {
                width: 100%;
                padding: calc(5rem/16) calc(32rem/16);
                background: $blueToPurpleGradient;
                text-shadow: calc(2rem/16) calc(2rem/16) calc(2rem/16) rgba(0, 0, 0, 0.4);
                &:hover,
                &:focus,
                &:active {
                    background: $blueToPurpleGradient;
                    color: $white;
                }
            }
        }

        .watch-trailer__primary-info {
            padding: 1rem !important;
            .primary-info__top-row,
            .primary-info__title-and-date,
            h1 {
                width: 100% !important;
            }
        }

        @media screen and (min-width: $breakpointSmallTablet) {
            &.is-not-theatre-mode {
                justify-content: center !important;
            }

            .watch__button {
                .ickonic-button {
                    width: auto;
                }
            }
        }

        @media screen and (min-width: $breakpointSmallTablet) {
            .watch__button,
            .watch-trailer__primary-info {
                width: calc(100% - (150rem/16)) !important;
            }
        }

        @media screen and (min-width: $breakpointTablet) {
            .watch__button,
            .watch-trailer__primary-info {
                width: calc(100% - (200rem/16)) !important;
            }
        }

        @media screen and (min-width: $breakpointDesktop) {
            .watch__button,
            .watch-trailer__primary-info {
                width: calc(100% - (250rem/16)) !important;
            }
        }
    }
}
