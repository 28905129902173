.front__hero {
    padding: 1rem;
    .layout--slider-featured-content {
        border-radius: calc(10rem/16);
        .slider-featured-content__shadow {
            display: none;
        }

        &.is-breakpoint-small-tablet {
            .slider-featured-content__dots {
                bottom: 1rem;
            }

            .slider-featured-content__inner {
                .slider-featured-content__slide {
                    padding: 4rem;
                }
            }
        }
    }

    .hero__articles {
        border-radius: calc(10rem/16);
    }

    @media screen and (min-width: $breakpointTablet) {
        .hero__inner {
            display: flex;
            justify-content: center;
            max-width: calc(1747rem/16);
            margin: 0 auto;
        }

        .layout--slider-featured-content {
            width: 50%;
            .slider-featured-content__inner.front-page {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
        }

        .hero__articles {
            width: 50%;
        }
    }

    @media screen and (min-width: $breakpointWide) {
        padding: 2rem;
        .layout--slider-featured-content {
            width: 70%;
        }

        .hero__articles {
            width: 30%;
        }
    }
}
