.watch-video__access-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.6);
    z-index: 10;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem;
    backdrop-filter: blur(calc(10rem/16));
    -webkit-backdrop-filter: blur(calc(10rem/16));
    .access-overlay__inner {
        padding: 1rem;
        .access-overlay__content {
            padding: 1rem;
            border-radius: calc(10rem/16);
            & > .ickonic-button {
                padding: calc(10rem/16) calc(32rem/16);
                background: $siteAccent;
                color: $black;
                animation: boxShadowGlowPulseGold 3s infinite ease-in-out;
                border-radius: calc(24rem/16);
                margin-top: 1.5rem;
                span {
                    font-size: calc(16rem/16);
                    font-family: $tertiaryFont;
                    text-transform: uppercase;
                }
            }
        }

        h2 {
            margin-top: 0;
            font-family: $tertiaryFont;
            background: linear-gradient(90deg, rgba(232,187,39,1) 0%, rgba(187,54,184,1) 100%);
            font-weight: bold;
            font-size: calc(30rem/16);
            -webkit-background-clip: text;
            color: transparent;
            @media screen and (min-width: $breakpointSmallTablet) {
                font-size: calc(40rem/16);
            }
        }

        @media screen and (min-height: calc(570em/16)) {
            transform: translateY(-10vh);
        }


        @media screen and (min-width: $breakpointSmallTablet) and (min-height: calc(950em/16)) {
            //transform: translateY(-15vh);
        }

        @media screen and (min-width: $breakpointSmallTablet) and (min-height: calc(950em/16)) {
            transform: translateY(-20vh);
        }
    }
}
