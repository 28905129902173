.cancel-or-pause__step-technical-difficulties {
    .tech-difficulties__error-text {
        color: $white;
        margin: 1rem 0;
        padding: calc(5rem/16) calc(10rem/16);
        box-shadow: inset 0 0 0 calc(1rem/16) $siteError;
        border-radius: calc(3rem/16);
        text-align: center;
    }

    p {
        &:first-of-type {
            margin-bottom: 2rem;
            @media screen and (min-width: $breakpointTablet) {
                margin-bottom: 1rem;
            }
        }
    }

    .tech-difficulties__checkboxes {
        .form__checkbox {
            margin: calc(10rem/16) 0;
            @media screen and (min-width: $breakpointTablet) {
                margin: calc(5rem/16) 0;
            }
        }

        @media screen and (min-width: $breakpointTablet) {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
            .form__checkbox {
                width: 50%;
            }
        }
    }

    .form__textarea {
        margin: 1rem 0;
    }

    .form__input-select {
        margin-top: 2rem;
        &:last-of-type {
            margin-top: 1rem;
        }

        @media screen and (min-width: $breakpointTablet) {
            margin-top: 1rem;
        }
    }

    .ickonic-button {
        display: table;
        margin: 2rem auto 0 auto;
    }
}
